const baseUrl = "https://icircles.app";

const getAllMicrositeUrl = `${baseUrl}/api/mymicrosites/microsites`;
const getSingleMicrositeDetailsUrl = `${baseUrl}/api/microsite/details`;
const settingsUpdateUrl = `${baseUrl}/api/littlemag/settings`;
const micrositeUpdateUrl = `${baseUrl}/api/mymicrosites/microsite`;
const getAllPagesUrl = `${baseUrl}/api/cms/pages`;
const micrositeWebCardUrl = `${baseUrl}/api/mymicrosites/micrositewebcard`;
const generateWebcardUrl = `${baseUrl}/images/webcards/microsites`;
const empWebCardUrl = `${baseUrl}/images/webcards/micrositepermission`;
const empGenerateWebcardUrl = `${baseUrl}/api/common/generateWebcard`;
const empGenerateWebHorizontalcardUrl = `${baseUrl}/api/common/generateHwebcard`;
const generateVerticleWebCardUrl = `${baseUrl}/api/mymicrosites/micrositewebcard`;
const generateHorozontalWebCardUrl = `${baseUrl}/api/common/generateHwebcard`;

const pageAllCategoriesUrl = `${baseUrl}/api/cms/pagecategories`;
const pageDeleteCategoryUrl = `${baseUrl}/api/cms/pagecategories`;
const pageStoreCategoryUrl = `${baseUrl}/api/cms/pagecategories`;
const parentPageTitlesUrl = `${baseUrl}/api/cms/pages`;
const getSinglePageDetails = `${baseUrl}/api/cms/pagebasedpost`;
const getSingleCategoryUrl = `${baseUrl}/api/cms/pagecategories`;
const createNewPageUrl = `${baseUrl}/api/cms/pages`;
const deletePageUrl = `${baseUrl}/api/cms/pages`;

const getAllMembersUrl = `${baseUrl}/api/massociation/members`;
const memberSearchUrl = `${baseUrl}/api/massociation/members`;
const userDeleteUrl = `${baseUrl}/api/common/user`;
const updateMemberUrl = `${baseUrl}/api/common/user`;

const userCheckUrl = `${baseUrl}/api/auth/users/usercheck`;
const memberRegisterUrl = `${baseUrl}/api/auth/register`;
const memberLoginUrl = `${baseUrl}/api/auth/login`;
const createCommonUserUrl = `${baseUrl}/api/common/user`;
const commonUserUrl = `${baseUrl}/api/common/user`;
const userNotifyUrl = `${baseUrl}/api/mymicrosites/notify`;
const userInfoUrl = `${baseUrl}/api/auth/user`;

const micrositeUrl = `${baseUrl}/api/mymicrosites/micrositepermission`;
const userProfileUrl = `${baseUrl}/api/auth/user`;
const otpLoginUrl = `${baseUrl}/api/auth/login/otp`;

const mediaUploadUrl = `${baseUrl}/api/cms/pages`;
const mediaDeleteUrl = `${baseUrl}/api/cms/pages`;

const getAllAuthorsList = `${baseUrl}/api/littlemag/authors`;
const createNewAuthorUrl = `${baseUrl}/api/littlemag/authors`;
const deleteAuthorUrl = `${baseUrl}/api/littlemag/authors`;
const updateAuthorUrl = `${baseUrl}/api/littlemag/authors`;

const ecommerceCategoryUrl = `${baseUrl}/api/ecommerce/category`;
const ecommerceProductUrl = `${baseUrl}/api/ecommerce/product`;
const ecommerceBrandUrl = `${baseUrl}/api/ecommerce/brand`;
const ecommerceOrderUrl = `${baseUrl}/api/ecommerce/order`;
const ecommerceMediaUrl = `${baseUrl}/api/ecommerce/category/media`;
const productWebcardUrl = `${baseUrl}/images/webcards/products`;

//media

const folderUrl = `${baseUrl}/api/qrdrive/myvault`;
const addMediaUrl = `${baseUrl}/api/qrdrive/media`;
const deleteMediaUrl = `${baseUrl}/api/qrdrive/media`;
const sentEmailUrl = `${baseUrl}/api/qrdrive/emailmedia`;
const editMediaUrl = `${baseUrl}/api/qrdrive/editmedia`;
const editMediaFolderUrl = `${baseUrl}/api/qrdrive/editfolder`;
const deleteFolderUrl = `${baseUrl}/api/qrdrive/deletefolder`;

//vault
const allAddsByMicrositeUrl = `${baseUrl}/api/common/vaultadvertisement`;

// jobs api
const allJobsUrl = `${baseUrl}/api/jobboard/jobs`;
const allJobCategoryUrl = `${baseUrl}/api/jobboard/categories`;

// leave api
const leavetypeUrl = `${baseUrl}/api/common/leavetype`;
const leaveapplicationUrl = `${baseUrl}/api/common/leaveapplication`;

//workshift
const workshiftUrl = `${baseUrl}/api/common/workshift`;

// notice
const noticeUrl = `${baseUrl}/api/common/hrnotice`;

// department
const departmentUrl = `${baseUrl}/api/common/HrDepartments`;


//attendence
const attendenceUrl = `${baseUrl}/api/common/attendence`;

//holiday
const holidayUrl= `${baseUrl}/api/common/holidays`;



//Classified api
const classifiedUrl = `${baseUrl}/api/classified/item`;
const ClassifiedCategoryUrl = `${baseUrl}/api/classified/categories`;
const classifiedBidsUrl = `${baseUrl}/api/classified/bids`;
const classifiedOrderUrl = `${baseUrl}/api/classified/Order`;
const classifiedMyOrderUrl = `${baseUrl}/api/classified/myorder`;


//Media
const micrositeAllMediaUrl = `${baseUrl}/api/mymicrosites/allmedia`;
const micrositeDeleteFolderUrl = `${baseUrl}/api/mymicrosites/deletefolder`;
const micrositeEditMediaFolderUrl =`${baseUrl}/api/mymicrosites/editfolder`;
const micrositeEditMediaUrl = `${baseUrl}/api/mymicrosites/editmedia`;
const micrositeAddMediaUrl = `${baseUrl}/api/mymicrosites/media`;

// ecommmerce suggestions
const ecomCatSuggestionsUrl = `${baseUrl}/api/ecommerce/catsuggestion`;
const ecomProductuggestionUrl = `${baseUrl}/api/ecommerce/productuggestion`;

const classUrl = `${baseUrl}/api/sms/class`; 
const shiftUrl = `${baseUrl}/api/sms/shift`;


// chapter
const chapterUrl = `${baseUrl}/api/massociation/chapter`;

//Quize
const questionUrl = `${baseUrl}/api/quiz/questions`
const answerUrl = `${baseUrl}/api/quiz/answers`

// lounge feed,comment and like
const createFeedsUrl = `${baseUrl}/api/community/feeds`;
const commentsUrl = `${baseUrl}/api/community/comments`;
const reactionsUrl = `${baseUrl}/api/community/postreaction`;
const commentReactionUrl = `${baseUrl}/api/community/commentsreaction`;




export {
  deletePageUrl,
  baseUrl,
  getAllPagesUrl,
  getAllMicrositeUrl,
  generateWebcardUrl,
  empWebCardUrl,
  productWebcardUrl,
  empGenerateWebcardUrl,
  empGenerateWebHorizontalcardUrl,
  generateVerticleWebCardUrl,
  generateHorozontalWebCardUrl,
  micrositeWebCardUrl,
  getSingleMicrositeDetailsUrl,
  settingsUpdateUrl,
  micrositeUpdateUrl,
  pageAllCategoriesUrl,
  getSinglePageDetails,
  getSingleCategoryUrl,
  createNewPageUrl,
  pageStoreCategoryUrl,
  pageDeleteCategoryUrl,
  parentPageTitlesUrl,
  getAllMembersUrl,
  memberSearchUrl,
  userDeleteUrl,
  userInfoUrl,
  updateMemberUrl,
  userCheckUrl,
  memberRegisterUrl,
  createCommonUserUrl,
  commonUserUrl,
  micrositeUrl,
  userProfileUrl,
  mediaUploadUrl,
  mediaDeleteUrl,
  getAllAuthorsList,
  createNewAuthorUrl,
  deleteAuthorUrl,
  updateAuthorUrl,
  memberLoginUrl,
  otpLoginUrl,
  ecommerceCategoryUrl,
  ecommerceProductUrl,
  ecommerceBrandUrl,
  ecommerceMediaUrl,
  ecommerceOrderUrl,
  allAddsByMicrositeUrl,
  folderUrl,
  addMediaUrl,
  deleteMediaUrl,
  sentEmailUrl,
  editMediaUrl,
  editMediaFolderUrl,
  deleteFolderUrl,
  allJobsUrl,
  allJobCategoryUrl,
  userNotifyUrl,

 micrositeAllMediaUrl ,
 micrositeDeleteFolderUrl ,
 micrositeEditMediaFolderUrl ,
 micrositeEditMediaUrl ,
 micrositeAddMediaUrl ,

 leavetypeUrl,
 leaveapplicationUrl,

 workshiftUrl,
 noticeUrl,

 departmentUrl,

 attendenceUrl,


 holidayUrl,


 ecomCatSuggestionsUrl,
 ecomProductuggestionUrl,

 classUrl,
 shiftUrl,

 chapterUrl,

 questionUrl,
 answerUrl,

 createFeedsUrl,
 commentsUrl,
 reactionsUrl,
 commentReactionUrl
};
