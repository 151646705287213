import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import {
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ExpensesAdd() {
  const navigate = useNavigate();

  // Payment Term Select
  const [tax, setTax] = useState("");
  const handleTax = (event) => {
    setTax(event.target.value);
  };

  //  Delivery Select
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const handleDeliveryMethod = (event) => {
    setDeliveryMethod(event.target.value);
  };

  //  Sales Person Select
  const [salesPerson, setSalesPerson] = useState("");
  const handleSalesPerson = (event) => {
    setSalesPerson(event.target.value);
  };

  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <>
      <TabContext value={tabValue}>
        <div className="page-top">
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Record Expense" value="1" />
            <Tab label="Record Mileage" value="2" />
            <Tab label="Bulk Add Expenses" value="3" />
          </TabList>
          <div className="page-top-buttons">
            <i onClick={(e) => navigate(-1)} className="cursor-pointer">
              <CloseIcon />
            </i>
          </div>
        </div>

        <TabPanel value="1">
          <div className="form-content">
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <div className="search-box d-flex">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    size="small"
                    sx={{
                      width: 400,
                      ".MuiAutocomplete-option": {
                        fontSize: "14px !important",
                        margin: "2px 0 !important",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Vendor Name" />
                    )}
                  />
                  <Button variant="contained" sx={{ ml: 1 }}>
                    <SearchIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="form-content">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="content-wrap">
                  <TextField
                    label="Date#"
                    id="outlined-size-small"
                    size="small"
                    focused
                    type="date"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="content-wrap">
                  <FormControl fullWidth size="small">
                    <InputLabel id="delivery-method-select">
                      Expense Account
                    </InputLabel>
                    <Select
                      labelId="delivery-method-select"
                      id="delivery-method-select-small"
                      value={deliveryMethod}
                      label="Delivery Method"
                      onChange={handleDeliveryMethod}
                    >
                      <MenuItem value="">
                        <em>
                          <small> None </small>
                        </em>
                      </MenuItem>
                      <MenuItem value={1}>
                        <small> Menu Item </small>
                      </MenuItem>
                      <MenuItem value={2}>
                        <small> Menu Item </small>
                      </MenuItem>
                      <MenuItem value={3}>
                        <small> Menu Item </small>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="content-wrap">
                  <TextField
                    label="Amount#"
                    id="outlined-size-small"
                    size="small"
                    fullWidth
                    type="number"
                  />
                </div>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ mt: 1 }}>
                <div className="content-wrap">
                  <TextField
                    label="Reference#"
                    id="outlined-size-small"
                    size="small"
                    fullWidth
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="content-wrap">
                  <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                    <InputLabel id="tax-term-select">Tax</InputLabel>
                    <Select
                      labelId="tax-term-select"
                      id="tax-term-select-small"
                      value={tax}
                      label="Tax "
                      onChange={handleTax}
                    >
                      <MenuItem value="">
                        <em>
                          <small> None </small>
                        </em>
                      </MenuItem>
                      <MenuItem value={1}>
                        <small> Menu Item </small>
                      </MenuItem>
                      <MenuItem value={2}>
                        <small> Menu Item </small>
                      </MenuItem>
                      <MenuItem value={3}>
                        <small> Menu Item </small>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="content-wrap">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-multiline-static"
                      label="Notes"
                      multiline
                      rows={2}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="content-wrap">
                  <FormControl fullWidth size="small">
                    <InputLabel id="sales-person-select">
                      Paid Through
                    </InputLabel>
                    <Select
                      labelId="sales-person-select"
                      id="sales-person-select-small"
                      value={salesPerson}
                      label="Sales Person"
                      onChange={handleSalesPerson}
                    >
                      <MenuItem value="">
                        <em>
                          <small> None </small>
                        </em>
                      </MenuItem>
                      <MenuItem value={1}>
                        <small> Menu Item </small>
                      </MenuItem>
                      <MenuItem value={2}>
                        <small> Menu Item </small>
                      </MenuItem>
                      <MenuItem value={3}>
                        <small> Menu Item </small>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={5}>
                <h6> Attach File(s) to inventory adjustment </h6>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  sx={{ mt: 1 }}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" />
                </Button>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value="2"> Record Mileage </TabPanel>
        <TabPanel value="3"> Bulk Add Expenses </TabPanel>

        <div className="fixed-save-area">
          <Button variant="outlined" size="small" onClick={(e) => navigate(-1)}>
            Save As Draft
          </Button>
          <Button variant="contained" size="small" sx={{ ml: 1 }}>
            Save and Send
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{ ml: 1 }}
            onClick={(e) => navigate(-1)}
          >
            Cancel
          </Button>
        </div>
      </TabContext>
    </>
  );
}

export default ExpensesAdd;

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
];
