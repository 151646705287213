import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "accountName", headerName: "Account Name", width: 320 },
  { field: "accountCode", headerName: "Account Code", width: 320 },
  { field: "type", headerName: "Type", width: 300 },
];

const rows = [
  {
    id: 1,
    accountName: "Accounts Payable",
    accountCode: " 42102 ",
    type: "Accounts Payable",
  },
  {
    id: 2,
    accountName: "Accounts Payable",
    accountCode: " 42102 ",
    type: "Accounts Payable",
  },
  {
    id: 3,
    accountName: "Accounts Payable",
    accountCode: " 42102 ",
    type: "Accounts Payable",
  },
  {
    id: 4,
    accountName: "Accounts Payable",
    accountCode: " 42102 ",
    type: "Accounts Payable",
  },
  {
    id: 5,
    accountName: "Accounts Payable",
    accountCode: " 42102 ",
    type: "Accounts Payable",
  },
];

const ChartOfAccounts = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4 className="cursor-pointer d-center">
          All Account
        </h4>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{mr: 1}}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) => navigate("/dashboard/accounts/chart-of-account-add")}>
             New Account
          </Button>
          <Button variant="outlined" className="icon-button">
              <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform : "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}/>
        </div>
      </div>
    </>
  );
};

export default ChartOfAccounts;
