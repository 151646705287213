import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

const columns = [
  { field: "date", headerName: "Date", width: 180 },
  { field: "currency", headerName: "Currnecy", width: 180 },
  { field: "exchangeRate", headerName: "Exchange Rate", width: 180 },
  { field: "gain", headerName:  "Gain & Loss", width: 160 },
  { field: "notes", headerName: "Notes",   width: 160 },
];

const rows = [
  {
    id: 1,
    date: "12 Oct 2024",
    currency: " USD ",
    exchangeRate: "--",
    gain: "Draft",
    notes: "--" ,
  },
  {
    id: 2,
    date: "13 Oct 2024",
    currency: " BDT ",
    exchangeRate: "--",
    gain: "Draft",
    notes: "--" ,
  },
  {
    id: 3,
    date: "14 Oct 2024",
    currency: " USD ",
    exchangeRate: "--",
    gain: "Draft",
    notes: "--" ,
  },
  {
    id: 4,
    date: "15 Oct 2024",
    currency: " USD ",
    exchangeRate: "--",
    gain: "Draft",
    notes: "--" ,
  },
  {
    id: 5,
    date: "16 Oct 2024",
    currency: " USD ",
    exchangeRate: "--",
    gain: "Draft",
    notes: "--" ,
  },
];

const CurrencyAdjustment = () => {

  const navigate = useNavigate();

  return (
    <>

      <div className="page-top">
        <h4
          className="cursor-pointer d-center">
          Base Currency Adjustments
        </h4>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}>
            Make an Adjustment
          </Button>

          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform : "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CurrencyAdjustment;