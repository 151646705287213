import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function DeliveryChallanAdd() {
  const navigate = useNavigate();

  // Payment Term Select
  const [challanType, setChallanType] = useState("");
  const handleChallanType = (event) => {
    setChallanType(event.target.value);
  };

  return (
    <>
      <div className="page-top">
        <h4> New Delivery Challan </h4>
        <div className="page-top-buttons">
          <i onClick={(e) => navigate(-1)} className="cursor-pointer">
            <CloseIcon />
          </i>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <div className="search-box d-flex">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                sx={{ width: 400 , 
                ".MuiAutocomplete-option": {
                  fontSize: "14px !important",
                  margin : "2px 0 !important"
                }, }}
                renderInput={(params) => (
                  <TextField {...params} label="Customer Name" />
                )}
              />
              <Button variant="contained" sx={{ ml: 1 }}>
                <SearchIcon />
              </Button>
            </div>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <div className="estimate-address">
                  <h6>
                    Shipping Address
                    <i className="cursor-pointer">
                      <EditIcon sx={{ fontSize: "13px" }} />
                    </i>
                  </h6>
                  <h6>
                    <strong> Tamara </strong>
                  </h6>
                  <h6>
                    928 Nitzsche Corners Apt. 059 66996 Eddie Station Suite 478
                    Fort Dandreburgh, Missouri,
                  </h6>
                  <h6> 072-467 </h6>
                  <h6> Grenada </h6>
                  <h6> Phone: (643)-373-161 </h6>
                  <h6> Fax Number: 636.541.7348 </h6>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Delivery Challan#"
                id="outlined-size-small"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Reference#"
                id="outlined-size-small"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Delivery Challan Date"
                id="outlined-size-small"
                size="small"
                focused
                type="date"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl fullWidth size="small">
                <InputLabel id="challan-type-select">Challan Type</InputLabel>
                <Select
                  labelId="challan-type-select"
                  id="challan-type-select-small"
                  value={challanType}
                  label="Challan Type"
                  onChange={handleChallanType}
                >
                  <MenuItem value="">
                  <em> <small> None </small> </em>
                  </MenuItem>
                  <MenuItem value={1}> <small> Menu Item </small> </MenuItem>
                  <MenuItem value={2}> <small> Menu Item </small> </MenuItem>
                  <MenuItem value={3}> <small> Menu Item </small> </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="form-content">
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th className="w-25">ITEM DETAILS</th>
                <th className="w-15 text-end">QUANTITY</th>
                <th className="w-15 text-end">RATE </th>
                <th className="w-15 text-end">DISCOUNT</th>
                <th className="w-15 text-end">TAX</th>
                <th className="w-15 text-end">AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-25">
                  <Input
                    className="table-input-field"
                    type="text"
                    placeholder="Type or click to select an item."
                    multiline
                    rows={2}
                    fullWidth
                  />
                </td>
                <td className="w-15 text-end"> 200 </td>
                <td className="w-15">
                  <Input
                    className="table-input-field input-end"
                    type="text"
                    placeholder="01.000"
                    fullWidth
                  />
                </td>
                <td className="w-15">
                  <Input
                    className="table-input-field input-end"
                    type="text"
                    placeholder="01.000"
                    fullWidth
                  />
                </td>
                <td className="w-15">
                  <Input
                    className="table-input-field input-end"
                    type="text"
                    placeholder="01.000"
                    fullWidth
                  />
                </td>
                <td className="w-15 text-end">$4000.00</td>
              </tr>

              <Button
                sx={{ mt: 1.5 }}
                size="small"
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
              >
                Add New Row
              </Button>
            </tbody>
          </table>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Customer Notes"
                  multiline
                  rows={4}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="account-card">
              <div className="account-card-top">
                <h5> Sub Total </h5>
                <h5> 00.00 </h5>
              </div>
              <div className="account-card-content">
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={4}>
                    <Input
                      className="account-input-field"
                      type="text"
                      placeholder="Adjustment"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      className="account-input-field"
                      type="text"
                      placeholder="01.000"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} className="text-end">
                    <h6> 00.00 </h6>
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 3 }}>
                  <Grid item xs={8}>
                    <h6> Round Off </h6>
                  </Grid>
                  <Grid item xs={4} className="text-end">
                    <h6> 00.00 </h6>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <div className="account-card-bottom">
                  <h5> Total ( $ ) </h5>
                  <h5> 00.00 </h5>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <div className="content-wrap">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Terms & Conditions"
                  multiline
                  rows={3}/>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={5}>
            <h6> Attach File(s) to inventory adjustment </h6>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              sx={{ mt: 1 }}>
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="fixed-save-area">
        <Button variant="contained" size="small" onClick={(e) => navigate(-1)}>
          Save As Draft
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          onClick={(e) => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default DeliveryChallanAdd;

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
];
