import { Button } from "@mui/material";
import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "date", headerName: "DATE", type: "number", width: 100 },
  { field: "description", headerName: "DESCRIPTION", width: 120 },
  { field: "status", headerName: "STATUS", width: 100 },
  { field: "reference", headerName: "REFERENCE", width: 100 },
  { field: "type", headerName: "TYPE", width: 100 },
  { field: "createdBy", headerName: "CREATED BY", width: 120 },
  {
    field: "createdTime",
    headerName: "CREATED TIME",
    type: "number",
    width: 100,
  },
  { field: "lastModifiedBy", headerName: "LAST MODIFY BY", width: 120 },
];

const rows = [
  {
    id: 1,
    date: "2 Jun 2023",
    description: " - ",
    status: "ADJUSTED",
    reference: "ADJ-0",
    type: " Value ",
    createdBy: "Demouser",
    createdTime: " - ",
    lastModifiedBy: " - ",
  },
  {
    id: 2,
    date: "1 Jun 2023",
    description: " - ",
    status: "ADJUSTED",
    reference: "ADJ-0",
    type: " Quantity ",
    createdBy: "Demouser",
    createdTime: " - ",
    lastModifiedBy: " - ",
  },
  {
    id: 3,
    date: "1 Jun 2023",
    description: " - ",
    status: "ADJUSTED",
    reference: "ADJ-0",
    type: " Value ",
    createdBy: "Demouser",
    createdTime: " - ",
    lastModifiedBy: " - ",
  },
  {
    id: 4,
    date: "1 Jun 2023",
    description: " - ",
    status: "ADJUSTED",
    reference: "ADJ-0",
    type: " Quantity ",
    createdBy: "Demouser",
    createdTime: " - ",
    lastModifiedBy: " - ",
  },
  {
    id: 5,
    date: "1 Jun 2023",
    description: " - ",
    status: "ADJUSTED",
    reference: "ADJ-0",
    type: null,
    createdBy: "Demouser",
    createdTime: " - ",
    lastModifiedBy: " - ",
  },
];

const InventoryAdjustment = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4 className="cursor-pointer d-center">Inventory Adjustments </h4>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) =>
              navigate("/dashboard/accounts/add-inventory-adjustment")
            }>
            New
          </Button>

          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform : "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>

        
      </div>
    </>
  );
};

export default InventoryAdjustment;
