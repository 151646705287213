import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "src/layouts/dashboard/api";

export const chapterSlice = createApi({
  reducerPath: "chapter",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["chapter"],
  endpoints: (builder) => ({
    getChapterData: builder.query({
      query: ({token,microsite_id,currentPage,searchValue}) => {
        return {
          url: `/api/massociation/chapter?microsite_id=${microsite_id}&current_page=${currentPage}&keyword=${searchValue}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["chapter"],
    }),
    getSingleChapterData: builder.query({
      query: ({uuid}) => {
        return {
          url: `/api/massociation/chapter/${uuid}`,
          method: "GET",
        };
      },
      providesTags: ["chapter"],
    }),
    storeChapter: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['chapter'],
    }),
    storeBulkChapter: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['chapter'],
    }),
    deleteChapter: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/massociation/chapter/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['chapter'],
    }),
  }),
});

export const {
    useGetChapterDataQuery,
    useGetSingleChapterDataQuery,
    useStoreChapterMutation,
    useStoreBulkChapterMutation,
    useDeleteChapterMutation
  } = chapterSlice;