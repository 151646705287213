import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

function TransactionLocking() {
  return (
    <>
      <div className="page-top">
        <h4> Transaction Locking </h4>
        <div className="page-top-buttons">
          <h6> Find Accountants </h6>
        </div>
      </div>

      <div className="common-container">
        <h6>
          Transaction locking prevents you and your users from making any
          changes to transactions that might affect your accounts. Once
          transactions are locked, users cannot edit, modify, or delete any
          transactions that were recorded before the specified date in this
          module.
        </h6>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={8}>
            <div className="transaction-card">
              <div className="transaction-card-left">
                <i>
                  <LockIcon />
                </i>
                <div className="lock-content">
                  <h5>
                    <strong> Sales </strong>
                  </h5>
                  <h6>You have not locked the transactions in this module.</h6>
                </div>
              </div>
              <div className="transaction-card-right">
                <div className="card-status">
                  <LockIcon sx={{ fontSize: "17px" }} /> Lock
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="transaction-card">
              <div className="transaction-card-left">
                <i>
                  <LockIcon />
                </i>
                <div className="lock-content">
                  <h5>
                    <strong> Purchases </strong>
                  </h5>
                  <h6>You have not locked the transactions in this module.</h6>
                </div>
              </div>
              <div className="transaction-card-right">
                <div className="card-status">
                  <LockIcon sx={{ fontSize: "17px" }} /> Lock
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="transaction-card">
              <div className="transaction-card-left">
                <i>
                  <LockIcon />
                </i>
                <div className="lock-content">
                  <h5>
                    <strong> Banking </strong>
                  </h5>
                  <h6>You have not locked the transactions in this module.</h6>
                </div>
              </div>
              <div className="transaction-card-right">
                <div className="card-status">
                  <LockIcon sx={{ fontSize: "17px" }} /> Lock
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="transaction-card">
              <div className="transaction-card-left">
                <i>
                  <LockIcon />
                </i>
                <div className="lock-content">
                  <h5>
                    <strong> Accountant </strong>
                  </h5>
                  <h6>You have not locked the transactions in this module.</h6>
                </div>
              </div>
              <div className="transaction-card-right">
                <div className="card-status">
                  <LockIcon sx={{ fontSize: "17px" }} /> Lock
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default TransactionLocking;
