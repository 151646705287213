import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ManualJournalsAdd() {
  const navigate = useNavigate();

  // Payment Term Select
  const [paymentTerm, setPaymentTerm] = useState("");
  const handlePaymentTerm = (event) => {
    setPaymentTerm(event.target.value);
  };

  //  Delivery Select
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const handleDeliveryMethod = (event) => {
    setDeliveryMethod(event.target.value);
  };

  //  Sales Person Select
  const [salesPerson, setSalesPerson] = useState("");
  const handleSalesPerson = (event) => {
    setSalesPerson(event.target.value);
  };

  return (
    <>
      <div className="page-top">
        <h4> Add Manual Journals </h4>
        <div className="page-top-buttons">
          <i onClick={(e) => navigate(-1)} className="cursor-pointer">
            <CloseIcon />
          </i>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Sales Date#"
                id="outlined-size-small"
                size="small"
                focused
                type="date"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Journal*"
                id="outlined-size-small"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Reference#"
                id="outlined-size-small"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl fullWidth size="small">
                <InputLabel id="payment-term-select">Currency Type</InputLabel>
                <Select
                  labelId="payment-term-select"
                  id="payment-term-select-small"
                  value={paymentTerm}
                  label="Payment Term"
                  onChange={handlePaymentTerm}
                >
                  <MenuItem value="">
                    <em>
                      {" "}
                      <small> None </small>{" "}
                    </em>
                  </MenuItem>
                  <MenuItem value={1}>
                    {" "}
                    <small> Menu Item </small>{" "}
                  </MenuItem>
                  <MenuItem value={2}>
                    {" "}
                    <small> Menu Item </small>{" "}
                  </MenuItem>
                  <MenuItem value={3}>
                    {" "}
                    <small> Menu Item </small>{" "}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Repellat"
                id="outlined-size-small"
                focused
                type="date"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Notes"
                  multiline
                  rows={3}
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="form-content">
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th className="w-25">ITEM DETAILS</th>
                <th className="w-15 text-end">QUANTITY</th>
                <th className="w-15 text-end">RATE </th>
                <th className="w-15 text-end">DISCOUNT</th>
                <th className="w-15 text-end">TAX</th>
                <th className="w-15 text-end">AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-25">
                  <Input
                    className="table-input-field"
                    type="text"
                    placeholder="Type or click to select an item."
                    multiline
                    rows={2}
                    fullWidth
                  />
                </td>
                <td className="w-15 text-end"> 200 </td>
                <td className="w-15">
                  <Input
                    className="table-input-field input-end"
                    type="text"
                    placeholder="01.000"
                    fullWidth
                  />
                </td>
                <td className="w-15">
                  <Input
                    className="table-input-field input-end"
                    type="text"
                    placeholder="01.000"
                    fullWidth
                  />
                </td>
                <td className="w-15">
                  <Input
                    className="table-input-field input-end"
                    type="text"
                    placeholder="01.000"
                    fullWidth
                  />
                </td>
                <td className="w-15 text-end">$4000.00</td>
              </tr>

              <Button
                sx={{ mt: 1.5 }}
                size="small"
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
              >
                Add New Row
              </Button>
            </tbody>
          </table>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Customer Notes"
                  multiline
                  rows={4}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="account-card">
              <div className="account-card-top">
                <h5> Sub Total </h5>
                <h5> 00.00 </h5>
              </div>
              <div className="account-card-content">
                <Grid container spacing={1} sx={{ mt: 3 }}>
                  <Grid item xs={8}>
                    <h6> Round Off </h6>
                  </Grid>
                  <Grid item xs={4} className="text-end">
                    <h6> 00.00 </h6>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <div className="account-card-bottom">
                  <h5> Total ( $ ) </h5>
                  <h5> 00.00 </h5>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={7}>
            
          </Grid>
          <Grid item xs={5}>
            <h6> Attach File(s) to inventory adjustment </h6>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              sx={{ mt: 1 }}
            >
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="fixed-save-area">
        <Button variant="outlined" size="small" onClick={(e) => navigate(-1)}>
          Save As Draft
        </Button>
        <Button variant="contained" size="small" sx={{ ml: 1 }}>
          Save and Send
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          onClick={(e) => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default ManualJournalsAdd;
