import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';
import { userListSlice } from './slices/userSlice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { acccountBankSlice } from './slices/acccountBankSlice';
import { acccountBalanceTransferSlice } from './slices/acccountBalanceTransferSlice';
import { medProMemberSlice } from './slices/medProMemberSlice';
import { chapterSlice } from './slices/chapterSlice';
import { mediaSlice } from './slices/mediaSlice';
import { quizQuestionsSlice } from './slices/QuizSlices';
import { QuizeMemberSlice } from './slices/QuizeMemberSlice';
import { MemberSlice } from './slices/MemberSlice';
import { loungeApiSlice } from './slices/loungeApiSlice';

// ----------------------------------------------------------------------
const persistedDataReducer =  persistReducer(rootPersistConfig, rootReducer)

const store = configureStore({
  reducer:{
    persistedDataReducer,
    [userListSlice.reducerPath]: userListSlice.reducer,
    [acccountBankSlice.reducerPath]: acccountBankSlice.reducer,
    [acccountBalanceTransferSlice.reducerPath]: acccountBalanceTransferSlice.reducer,
    [medProMemberSlice.reducerPath]: medProMemberSlice.reducer,
    [chapterSlice.reducerPath]: chapterSlice.reducer,
    [mediaSlice.reducerPath]: mediaSlice.reducer,
    [quizQuestionsSlice.reducerPath]: quizQuestionsSlice.reducer,
    [QuizeMemberSlice.reducerPath]: QuizeMemberSlice.reducer,
    [MemberSlice.reducerPath]: MemberSlice.reducer,
    [loungeApiSlice.reducerPath]: loungeApiSlice.reducer,
  }, 
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      userListSlice.middleware,
      acccountBankSlice.middleware,
      acccountBalanceTransferSlice.middleware,
      medProMemberSlice.middleware,
      chapterSlice.middleware,
      mediaSlice.middleware,
      quizQuestionsSlice.middleware,
      QuizeMemberSlice.middleware,
      MemberSlice.middleware,
      loungeApiSlice.middleware,
  ),
});
setupListeners(store.dispatch);
const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
