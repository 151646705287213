import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ChartOfAccountAdd() {
  const navigate = useNavigate();

  // Payment Term Select
  const [accountType, setAccountType] = useState("");
  const handleAccountType = (event) => {
    setAccountType(event.target.value);
  };

  //  Delivery Select
  const [repellat, setRepellat] = useState("");
  const handleRepellat = (event) => {
    setRepellat(event.target.value);
  };

  return (
    <>
      <div className="page-top">
        <h4> Add Manual Journals </h4>
        <div className="page-top-buttons">
          <i onClick={(e) => navigate(-1)} className="cursor-pointer">
            <CloseIcon />
          </i>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
        <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl fullWidth size="small">
                <InputLabel id="account-type-select"> Account Type </InputLabel>
                <Select
                  labelId="account-type-select"
                  id="account-type-select-small"
                  value={accountType}
                  label="Account Type"
                  onChange={handleAccountType}
                >
                  <MenuItem value="">
                    <em>
                      <small> None </small>
                    </em>
                  </MenuItem>
                  <MenuItem value={1}>
                    <small> Menu Item </small>
                  </MenuItem>
                  <MenuItem value={2}>
                    <small> Menu Item </small>
                  </MenuItem>
                  <MenuItem value={3}>
                    <small> Menu Item </small>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Account Name"
                id="outlined-size-small"
                size="small"
                focused
                type="date"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Account Code"
                id="outlined-size-small"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
          <div className="content-wrap">
              <FormControl fullWidth size="small">
                <InputLabel id="repellat-select"> Repellat </InputLabel>
                <Select
                  labelId="repellat-select"
                  id="repellat-select-small"
                  value={repellat}
                  label="Repellat"
                  onChange={handleRepellat}
                >
                  <MenuItem value="">
                    <em>
                      <small> None </small>
                    </em>
                  </MenuItem>
                  <MenuItem value={1}>
                    <small> Menu Item </small>
                  </MenuItem>
                  <MenuItem value={2}>
                    <small> Menu Item </small>
                  </MenuItem>
                  <MenuItem value={3}>
                    <small> Menu Item </small>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="fixed-save-area">
        <Button variant="outlined" size="small" onClick={(e) => navigate(-1)}>
          Save As Draft
        </Button>
        <Button variant="contained" size="small" sx={{ ml: 1 }}>
          Save and Send
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          onClick={(e) => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default ChartOfAccountAdd;
