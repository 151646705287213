import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "name", headerName: "Name", width: 160 },
  { field: "companyName", headerName: "Company Name", width: 150 },
  { field: "email", headerName: "E-mail", width: 100 },
  { field: "phone", headerName:  "Phone", width: 100 },
  { field: "receivables", headerName: "Receivables", type: "number",  width: 150 },
  { field: "unusedCredits", headerName: "Unused Credits",  type: "number",  width: 150 },
];

const rows = [
  {
    id: 1,
    name: "Ms. Derek Considine",
    companyName: "Item 1 ",
    email: "-",
    phone: "-",
    receivables: 1211 ,
    unusedCredits: 711,
  },
  {
    id: 2,
    name: "Marion Pacocha IV",
    companyName: "Item 2",
    email: "-",
    phone: "-",
    receivables: 211,
    unusedCredits: 457,
  },
  {
    id: 3,
    name: "Pat Tillman",
    companyName: "Item 3",
    email: "-",
    phone: "-",
    receivables: 1211,
    unusedCredits: 811,
  },
  {
    id: 4,
    name: "Ms. Derek Considine",
    companyName: "Item 4",
    email: "-",
    phone: "-",
    receivables: 8811,
    unusedCredits: 911,
  },
  {
    id: 5,
    name: "Alfonso Gleason",
    companyName: "Item 5",
    email: "-",
    phone: "-",
    receivables: 4211,
    unusedCredits: 3511,
  },
];

const Customers = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4
          className="cursor-pointer d-center"
          id="section-header"
          aria-controls={open ? "section-header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          All Customers <ArrowDropDownIcon />
        </h4>
        <Menu
          id="section-header-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "section-header",
          }}
        >
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            My account
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Logout
          </MenuItem>
        </Menu>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) => navigate("/dashboard/accounts/customers-add")}>
            New
          </Button>

          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform : "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Customers;
