import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddBank() {
  const navigate = useNavigate();

  // Currency Select
  const [currency, setCurrency] = useState("");
  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <>
      <div className="page-top">
        <h4> Add Bank or Credit Card </h4>
        <div className="page-top-buttons">
          <i onClick={(e) => navigate(-1)} className="cursor-pointer">
            <CloseIcon />
          </i>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                Select Account Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <div className="radio-card">
                    <FormControlLabel
                      value="quantityAdjustment"
                      control={<Radio />}
                      label="Bank"
                    />
                  </div>
                  <div className="radio-card">
                    <FormControlLabel
                      value="valueAdjustment"
                      control={<Radio />}
                      label="Credit Card"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            <div className="content-wrap">
              <TextField
                label="Account Name"
                id="outlined-size-small"
                size="small"
                fullWidth
                sx={{ mt: 1.2 }}
              />
            </div>
            <div className="content-wrap">
              <TextField
                id="outlined-size-small"
                size="small"
                type="number"
                label="Account Code"
                fullWidth
                sx={{ mt: 1.2 }}
              />
            </div>
            <div className="content-wrap">
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <InputLabel id="currency-select">Currency</InputLabel>
                <Select
                  labelId="currency-select"
                  id="currency-select-small"
                  value={currency}
                  label="Currency"
                  onChange={handleCurrency}
                >
                  <MenuItem value="">
                  <em> <small> None </small> </em>
                  </MenuItem>
                  <MenuItem value={10} sx={{ fontSize: "13px" }}>Ten</MenuItem>
                  <MenuItem value={20} sx={{ fontSize: "13px" }}>Twenty</MenuItem>
                  <MenuItem value={30} sx={{ fontSize: "13px" }}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="content-wrap">
              <TextField
                label="Account Number"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                sx={{ mt: 1.2 }}
              />
            </div>
            <div className="content-wrap">
              <TextField
                label="Bank Name"
                id="outlined-size-small"
                size="small"
                fullWidth
                sx={{ mt: 1.2 }}
              />
            </div>
            <div className="content-wrap">
              <TextField
                label="Routing Number"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                sx={{ mt: 1.2 }}
              />
            </div>
            <div className="content-wrap">
              <FormControl sx={{ mt: 1.2 }} fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="fixed-save-area">
        <Button variant="contained" size="small">
          Save
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          onClick={(e) => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default AddBank;
