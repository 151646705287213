import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "src/layouts/dashboard/api";

export const quizQuestionsSlice = createApi({
  reducerPath: "quiz_questions",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["quiz_questions"],
  endpoints: (builder) => ({
    getQuizQuestions: builder.query({
      query: ({token,microsite_id,currentPage,searchValue,type,category}) => {
        return {
          url: `api/quiz/questions?microsite_id=${microsite_id}&current_page=${currentPage}&keyword=${searchValue}&type=${type}&category=${category}`,
          method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
        };
      },
      providesTags: ["quiz_questions"],
    }),

    getQuizQuestionsCategory: builder.query({
      query: ({token,microsite_id}) => {
        return {
          url: `api/quiz/questions?microsite_id=${microsite_id}`,
          method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
        };
      },
      providesTags: ["quiz_questions"],
    }),
    storeQuestion: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['quiz_questions'],
    }),
    storeAnswer: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['quiz_questions'],
    }),
    getSingleQuestionData: builder.query({
      query: ({ token,uuid}) => {
        return {
          url: `api/quiz/questions/${uuid}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["quiz_questions"],
    }),

    deleteQuestion: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/quiz/questions/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['quiz_questions'],
    }),

    deleteAnswer: builder.mutation({
      query: ({ token, uuid }) => {
        return {
          url: `api/quiz/answers/${uuid}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["quiz_questions"],
    }),
  }),
});

export const {
  useGetQuizQuestionsQuery,
  useGetQuizQuestionsCategoryQuery,
  useStoreQuestionMutation,
  useStoreAnswerMutation,
  useGetSingleQuestionDataQuery,
  useDeleteQuestionMutation,
  useDeleteAnswerMutation,
} = quizQuestionsSlice;
