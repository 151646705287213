import PropTypes from "prop-types";
import { ReactSession } from "react-client-session";
import { Container, Alert, AlertTitle } from "@mui/material";
import { getSingleMicrositeDetailsUrl, userInfoUrl } from "src/layouts/dashboard/api";
import axios from "axios";

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ accessibleRoles, children }) {
  ReactSession.setStoreType("sessionStorage");

  const MicrositeInfo = ReactSession.get("MicrositeInfo");
  if (!ReactSession.get("MicrositeInfo") && !ReactSession.get("UserInfo")){
    window.location.href = "/auth/login";
  }
   
  if (
    !ReactSession.get("MicrositeInfo") &&
    !ReactSession.get("UserInfo") &&
    ReactSession.get("email") &&
    ReactSession.get("Uuid") 
  ){
    window.location.href = "/auth/otp";
  }
   
  if (!ReactSession.get("MicrositeInfo") && ReactSession.get("UserInfo")){
    window.location.href = "/auth/microsites";
  }

   
  return <>{children}</>;
}
