import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddAdjustment() {
  const navigate = useNavigate();

  // Unit Select
  const [reason, setReason] = useState("");
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  // Account Select
  const [account, setAccount] = useState("");
  const handleAccount = (event) => {
    setAccount(event.target.value);
  };

  return (
    <>
      <div className="page-top">
        <h4> New Price List </h4>
        <div className="page-top-buttons">
          <i onClick={(e) => navigate(-1)} className="cursor-pointer">
            <CloseIcon />
          </i>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Mode of adjustment
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <div className="radio-card">
                    <FormControlLabel
                      value="quantityAdjustment"
                      control={<Radio />}
                      label="Quantity"
                    />
                  </div>
                  <div className="radio-card">
                    <FormControlLabel
                      value="valueAdjustment"
                      control={<Radio />}
                      label="Value"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            <div className="content-wrap">
              <TextField
                label="Reference Number"
                id="outlined-size-small"
                size="small"
                fullWidth
                sx={{ mt: 1.2 }}
              />
            </div>
            <div className="content-wrap">
              <TextField
                id="outlined-size-small"
                size="small"
                type="date"
                fullWidth
                sx={{ mt: 1.2 }}
              />
            </div>
            <div className="content-wrap">
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <InputLabel id="account-select">Account</InputLabel>
                <Select
                  labelId="account-select"
                  id="account-select-small"
                  value={account}
                  label="Account"
                  onChange={handleAccount}
                >
                  <MenuItem value="">
                  <em> <small> None </small> </em>
                  </MenuItem>
                  <MenuItem value={1}> <small> Menu Item </small> </MenuItem>
                  <MenuItem value={2}> <small> Menu Item </small> </MenuItem>
                  <MenuItem value={3}> <small> Menu Item </small> </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="content-wrap">
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <InputLabel id="reason-select">Reason</InputLabel>
                <Select
                  labelId="reason-select"
                  id="account-select-small"
                  value={reason}
                  label="Reason"
                  onChange={handleReasonChange}
                >
                  <MenuItem value="">
                  <em> <small> None </small> </em>
                  </MenuItem>
                  <MenuItem value={1}> <small> Menu Item </small> </MenuItem>
                  <MenuItem value={2}> <small> Menu Item </small> </MenuItem>
                  <MenuItem value={3}> <small> Menu Item </small> </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="content-wrap">
              <FormControl sx={{ mt: 1.2 }} fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>

        <div className="custom-table mt-20">
          <table>
            <thead>
              <tr>
                <th className="w-40">ITEM DETAILS</th>
                <th className="w-20 text-end">QUANTITY AVAILABLE</th>
                <th className="w-20 text-end">NEW QUANTITY ON HAND</th>
                <th className="w-20 text-end">QUANTITY ADJUSTED</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-40">
                  <Input
                    className="table-input-field"
                    type="text"
                    placeholder="Type or click to select an item."
                    multiline
                    rows={2}
                    fullWidth
                  />
                </td>
                <td className="w-20 text-end"> 200 </td>
                <td className="w-20">
                  <Input
                    className="table-input-field input-end"
                    type="text"
                    placeholder="01.000"
                    fullWidth
                  />
                </td>
                <td className="w-20">
                  <Input
                    className="table-input-field input-end"
                    type="text"
                    placeholder="01.000"
                    fullWidth
                  />
                </td>
              </tr>

              <Button
                sx={{ mt: 1.5 }}
                size="small"
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}>
                Add New Row
              </Button>
            </tbody>
          </table>
        </div>

        <h6 className="mt-30"> Attach File(s) to inventory adjustment </h6>
        <Button
          component="label"
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          sx={{mt:1}}>
          Upload file
          <VisuallyHiddenInput type="file" />
        </Button>
      </div>
      <div className="fixed-save-area">
        <Button variant="contained" size="small">
          Save
        </Button>
        <Button variant="outlined" size="small" sx={{ ml: 1 }}>
          Convert to Adjusted
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          onClick={(e) => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default AddAdjustment;
