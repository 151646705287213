import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

// Tab Function
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function VendorsAdd() {
  const navigate = useNavigate();

  // Account Select
  const [account, setAccount] = useState("");
  const handleAccount = (event) => {
    setAccount(event.target.value);
  };

  // Tax Select
  const [sellTax, setSellTax] = useState("");
  const handleSellTax = (event) => {
    setSellTax(event.target.value);
  };

  // Unit Select
  const [language, setLanguage] = useState("");
  const handleLanguage = (event) => {
    setLanguage(event.target.value);
  };

  // Billing Country
  const [billingCountry, setBillingCountry] = useState("");
  const handleBillingCountry = (event) => {
    setBillingCountry(event.target.value);
  };

  // Shipping Country
  const [shippingCountry, setShippingCountry] = useState("");
  const handleShippingCountry = (event) => {
    setShippingCountry(event.target.value);
  };

  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className="page-top">
        <h4> New Vendor </h4>
        <div className="page-top-buttons">
          <i onClick={(e) => navigate(-1)} className="cursor-pointer">
            <CloseIcon />
          </i>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Full Name"
                id="outlined-size-small"
                size="small"
                fullWidth
                // variant="filled"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Company Name"
                id="outlined-size-small"
                size="small"
                fullWidth
                // variant="filled"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Vendor Display Name"
                id="outlined-size-small"
                size="small"
                fullWidth
                // variant="filled"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Vendor Email"
                id="outlined-size-small"
                size="small"
                fullWidth
                // variant="filled"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Vendor Phone"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                // variant="filled"
              />
            </div>
          </Grid>
        </Grid>

        <div className="tab-form">
          <Box sx={{ width: "100%", mt: 5 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Other Details" {...a11yProps(0)} />
                <Tab label="Address" {...a11yProps(1)} />
                <Tab label="Contact Persons" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="content-wrap">
                    <TextField
                      label="Currency"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      fullWidth
                      // variant="filled"
                    />
                  </div>
                  <div className="content-wrap">
                    <FormControl fullWidth size="small" sx={{ mt: 1.2 }}>
                      <InputLabel id="sell-tax-select">Tax Rate %</InputLabel>
                      <Select
                        labelId="sell-tax-select"
                        id="sell-tax-select-small"
                        value={sellTax}
                        label="Account"
                        onChange={handleSellTax}
                      >
                        <MenuItem value="">
                          <em>
                            <small> None </small>
                          </em>
                        </MenuItem>
                        <MenuItem value={1}>
                          <small> Menu Item </small>
                        </MenuItem>
                        <MenuItem value={2}>
                          <small> Menu Item </small>
                        </MenuItem>
                        <MenuItem value={3}>
                          <small> Menu Item </small>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="content-wrap">
                    <TextField
                      label="Opening Balance"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      fullWidth
                      sx={{ mt: 1.2 }}
                      // variant="filled"
                    />
                  </div>
                  <div className="content-wrap">
                    <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                      <InputLabel id="account-select">Payment Terms</InputLabel>
                      <Select
                        labelId="account-select"
                        id="account-select-small"
                        value={account}
                        label="Account"
                        onChange={handleAccount}
                      >
                        <MenuItem value="">
                          <em>
                            <small> None </small>
                          </em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="content-wrap">
                    <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                      <InputLabel id="language-select">Language</InputLabel>
                      <Select
                        labelId="language-select"
                        id="language-select-small"
                        value={language}
                        label="language"
                        onChange={handleLanguage}
                      >
                        <MenuItem value="">
                          <em>
                            <small> None </small>
                          </em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="content-wrap">
                    <FormControlLabel
                      value="end"
                      control={<Checkbox />}
                      label="Allow portal access for this customer"
                      labelPlacement="end"
                      sx={{ fontSize: "12px" }}
                    />
                  </div>
                  {/* <div className="content-wrap">
                    <FormControl sx={{ mt: 1.2 }} fullWidth>
                      <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={3}
                      />
                    </FormControl>
                  </div> */}
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h4> Billing Address </h4>
                  <div className="content-wrap">
                    <TextField
                      label="Attention"
                      id="outlined-size-small"
                      size="small"
                      fullWidth
                      // variant="filled"
                    />
                  </div>
                  <div className="content-wrap">
                    <FormControl fullWidth size="small" sx={{ mt: 1.2 }}>
                      <InputLabel id="billing-country-select">
                        Country
                      </InputLabel>
                      <Select
                        labelId="billing-country-select"
                        id="billing-country-select-select-small"
                        value={billingCountry}
                        label="billingCountry"
                        onChange={handleBillingCountry}
                      >
                        <MenuItem value="">
                          <em>
                            <small> None </small>
                          </em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="content-wrap">
                    <FormControl sx={{ mt: 1.2 }} fullWidth>
                      <TextField
                        id="outlined-multiline-static"
                        label="Address"
                        multiline
                        rows={2}
                      />
                    </FormControl>
                  </div>
                  <div className="content-wrap">
                    <TextField
                      sx={{ mt: 1.2 }}
                      label="City"
                      id="outlined-size-small"
                      size="small"
                      fullWidth
                      // variant="filled"
                    />
                  </div>
                  <div className="content-wrap">
                    <TextField
                      sx={{ mt: 1.2 }}
                      label="Zip Code"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </div>
                  <div className="content-wrap">
                    <TextField
                      sx={{ mt: 1.2 }}
                      label="Phone"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </div>
                  <div className="content-wrap">
                    <TextField
                      sx={{ mt: 1.2 }}
                      label="Fax Number"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <h4> Shipping Address </h4>
                  <div className="content-wrap">
                    <TextField
                      label="Attention"
                      id="outlined-size-small"
                      size="small"
                      fullWidth
                      // variant="filled"
                    />
                  </div>
                  <div className="content-wrap">
                    <FormControl fullWidth size="small" sx={{ mt: 1.2 }}>
                      <InputLabel id="shipping-country-select">
                        Country
                      </InputLabel>
                      <Select
                        labelId="shipping-country-select"
                        id="shipping-country-select-select-small"
                        value={shippingCountry}
                        label="shippingCountry"
                        onChange={handleShippingCountry}
                      >
                        <MenuItem value="">
                          <em>
                            <small> None </small>
                          </em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="content-wrap">
                    <FormControl sx={{ mt: 1.2 }} fullWidth>
                      <TextField
                        id="outlined-multiline-static"
                        label="Address"
                        multiline
                        rows={2}
                      />
                    </FormControl>
                  </div>
                  <div className="content-wrap">
                    <TextField
                      sx={{ mt: 1.2 }}
                      label="City"
                      id="outlined-size-small"
                      size="small"
                      fullWidth
                      // variant="filled"
                    />
                  </div>
                  <div className="content-wrap">
                    <TextField
                      sx={{ mt: 1.2 }}
                      label="Zip Code"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </div>
                  <div className="content-wrap">
                    <TextField
                      sx={{ mt: 1.2 }}
                      label="Phone"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </div>
                  <div className="content-wrap">
                    <TextField
                      sx={{ mt: 1.2 }}
                      label="Fax Number"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="note-text mt-30">
                <h4> Note </h4>
                <ul>
                  <li>
                    You can add and manage additional addresses from Customers
                    and Vendors details section.
                  </li>
                  <li>
                    View and edit the address format of your transactions under
                    Settings , Preferences , Customers and Vendors.
                  </li>
                </ul>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              Contact Person Tab
            </CustomTabPanel>
          </Box>
        </div>
      </div>

      <div className="fixed-save-area">
        <Button variant="contained" size="small">
          Save
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          onClick={(e) => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default VendorsAdd;