import { Button, Divider, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { LineChart } from "@mui/x-charts/LineChart";

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];
const Banking = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4 className="cursor-pointer d-center"> Banking Overview </h4>
        <div className="page-top-buttons">
          <Button variant="outlined" sx={{ mr: 2 }} 
          onClick={(e) =>
            navigate("/dashboard/accounts/import-statement")}>
            Import Statement
          </Button>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) =>
              navigate("/dashboard/accounts/add-bank")
            }
          >
            Add Bank or Credit Card
          </Button>
        </div>
      </div>

      <div className="banking-page-content">
        <div className="banking-top">
          <h4
            className="cursor-pointer d-center"
            id="section-header"
            aria-controls={open ? "section-header-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            All Items <ArrowDropDownIcon />
          </h4>
          <Menu
            id="section-header-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "section-header",
            }}
          >
            <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
              Profile
            </MenuItem>
            <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
              My account
            </MenuItem>
            <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
              Logout
            </MenuItem>
          </Menu>
          <div className="page-top-buttons">
            <Button variant="outlined" size="small">
              Last 30 days
              <ArrowDropDownIcon />
            </Button>
          </div>
        </div>
        <Divider />

        <div className="balance-card-wrapper">
          <div className="balance-card">
            <div className="balance-card-icon color-1">
              <AccountBalanceIcon fontSize="small" />
            </div>
            <div className="balance-card-content">
              <h6> Bank Balance </h6>
              <h5> $7481 </h5>
            </div>
          </div>

          <div className="balance-card">
            <div className="balance-card-icon color-2">
              <CreditCardIcon fontSize="small" />
            </div>
            <div className="balance-card-content">
              <h6> Card Balance </h6>
              <h5> $2835 </h5>
            </div>
          </div>

          <div className="balance-card">
            <div className="balance-card-icon color-3">
              <CurrencyExchangeIcon fontSize="small" />
            </div>
            <div className="balance-card-content">
              <h6> Cash In Hand </h6>
              <h5> $-835 </h5>
            </div>
          </div>
          <div className="balance-card">
            <div className="balance-card-icon color-4">
              <DoneOutlineIcon fontSize="small" />
            </div>
            <div className="balance-card-content">
              <h6> Payment Clearing </h6>
              <h5> $346 </h5>
            </div>
          </div>
        </div>
        <div className="mt-50">
          <LineChart
            height={400}
            series={[
              {
                data: uData,
                label: "uv",
                area: true,
                showMark: false,
                color: "#42ae78",
              },
            ]}
            xAxis={[{ scaleType: "point", data: xLabels }]}
            sx={{
              ".MuiLineElement-root": {
                display: "none",
              },
            }}
          />
        </div>
      </div>

      <div className="banking-page-content">
        <div className="banking-top">
          <h4
            className="cursor-pointer d-center"
            id="section-header"
            aria-controls={open ? "section-header-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Active Accounts <ArrowDropDownIcon />
          </h4>
          <Menu
            id="section-header-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "section-header",
            }}
          >
            <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
              Profile
            </MenuItem>
            <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
              My account
            </MenuItem>
            <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
              Logout
            </MenuItem>
          </Menu>
        </div>

        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th className="w-30">ACCOUNT DETAILS</th>
                <th className="w-20 text-center">UNCATEGORIZED</th>
                <th className="w-20 text-center">AMOUNT IN BANK</th>
                <th className="w-20 text-end">AMOUNT IN ZOHO BOOKS</th>
                <th className="w-10 text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-30">
                  <div className="balance-card">
                    <div className="balance-card-icon color-1">
                      <AccountBalanceIcon fontSize="small" />
                    </div>
                    <div className="balance-card-content">
                      <h6> Bank Account </h6>
                      <h5> #0030922112 </h5>
                    </div>
                  </div>
                </td>
                <td className="w-20 text-center">
                  
                  <span className="color-red"> 198 transactions </span>
                </td>
                <td className="w-20 text-center">
                  
                  <span> $70704259 </span>
                </td>
                <td className="w-20 text-end">
                  <span> $94351313 </span>
                </td>
                <td className="w-10 text-end">
                  <Button variant="outlined" className="icon-button">
                    <MoreVertIcon />
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="w-30">
                  <div className="balance-card">
                    <div className="balance-card-icon color-2">
                      <AccountBalanceIcon fontSize="small" />
                    </div>
                    <div className="balance-card-content">
                      <h6> Cash Account </h6>
                      <h5> #0098022115 </h5>
                    </div>
                  </div>
                </td>
                <td className="w-20 text-center">
                  
                  <span className="color-red"> 168 transactions </span>
                </td>
                <td className="w-20 text-center">
                  
                  <span> $70704259 </span>
                </td>
                <td className="w-20 text-end">
                  <span> $94353188 </span>
                </td>
                <td className="w-10 text-end">
                  <Button variant="outlined" className="icon-button">
                    <MoreVertIcon />
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="w-30">
                  <div className="balance-card">
                    <div className="balance-card-icon color-1">
                      <AccountBalanceIcon fontSize="small" />
                    </div>
                    <div className="balance-card-content">
                      <h6> Bank Account </h6>
                      <h5> #0098022119 </h5>
                    </div>
                  </div>
                </td>
                <td className="w-20 text-center">
                  
                  <span className="color-red"> 112 transactions </span>
                </td>
                <td className="w-20 text-center">
                  
                  <span> $70704252 </span>
                </td>
                <td className="w-20 text-end">
                  <span> $94353181 </span>
                </td>
                <td className="w-10 text-end">
                  <Button variant="outlined" className="icon-button">
                    <MoreVertIcon />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Banking;
