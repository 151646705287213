// ----------------------------------------------------------------------

export const fullName = [
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
  'Loading Name...',
];

export const firstName = [
  'Mossie',
  'David',
  'Ebba',
  'Chester',
  'Eula',
  'Jaren',
  'Boyd',
  'Brady',
  'Aida',
  'Anastasia',
  'Gregoria',
  'Julianne',
  'Ila',
  'Elyssa',
  'Lucio',
  'Lewis',
  'Jacinthe',
  'Molly',
  'Brown',
  'Fritz',
  'Keon',
  'Ella',
  'Ken',
  'Whitney',
  'Monte',
  'Rose',
  'Shana',
  'Devon',
  'Jaleel',
  'Laury',
  'Brooks',
  'Bruce',
  'Avery',
  'Esperanza',
  'Helene',
  'Heloise',
  'Elinor',
  'Adeline',
  'Haley',
  'Anabelle'
];

export const lastName = [
  'Carroll',
  'Simonis',
  'Yost',
  'Hand',
  'Emmerich',
  'Wilderman',
  'Howell',
  'Sporer',
  'Boehm',
  'Morar',
  'Koch',
  'Reynolds',
  'Padberg',
  'Watsica',
  'Upton',
  'Yundt',
  'Pfeffer',
  'Parker',
  'Zulauf',
  'Treutel',
  'McDermott',
  'McDermott',
  'Cruickshank',
  'Parisian',
  'Auer',
  'Turner',
  'Dooley',
  'Wiegand',
  'Abbott',
  'Wisoky',
  'Fahey',
  'Satterfield',
  'Bahringer',
  'Schulist',
  'Durgan',
  'Carroll',
  'Jones',
  'Leffler',
  'Gutkowski',
  'Homenick'
];
