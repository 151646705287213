import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "date", headerName: "Date", width: 100 },
  { field: "retainerInvoiceNumber", headerName: "Retainer Invoice Number", width: 160 },
  { field: "reference", headerName: "Reference#", type: "number", width: 120 },
  { field: "customerName", headerName:  "Customer Name", width: 160 },
  { field: "project", headerName:  "Project / Estimate", width: 100 },
  { field: "status", headerName: "Status",  width: 100 },
  { field: "amount", headerName: "Amount",  type: "number",  width: 100 },
];

const rows = [
  {
    id: 1,
    date: "23 Jun 2023",
    retainerInvoiceNumber: "EST-9405",
    reference: "2567",
    customerName: "Stefan",
    project: "--",
    status: "Invoiced",
    amount: 711,
  },
  {
    id: 2,
    date: "19 Jun 2023",
    retainerInvoiceNumber: "EST-6505",
    reference: "2567",
    customerName: "Stefan",
    project: "--",
    status: "Invoiced",
    amount: 711,
  },
  {
    id: 3,
    date: "18 Jun 2023",
    retainerInvoiceNumber: "EST-4405",
    reference: "2567",
    customerName: "Stefan",
    project: "--",
    status: "Invoiced",
    amount: 711,
  },
  {
    id: 4,
    date: "15 Jun 2023",
    retainerInvoiceNumber: "EST-9495",
    reference: "2567",
    customerName: "Stefan",
    project: "--",
    status: "Invoiced",
    amount: 711,
  },
  {
    id: 5,
    date: "14 Jun 2023",
    retainerInvoiceNumber: "EST-9403",
    reference: "2567",
    customerName: "Stefan",
    project: "--",
    status: "Invoiced",
    amount: 711,
  },
];

const RetainerInvoices = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4
          className="cursor-pointer d-center"
          id="section-header"
          aria-controls={open ? "section-header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}>
          All Estimates <ArrowDropDownIcon />
        </h4>
        <Menu
          id="section-header-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 
            "aria-labelledby": "section-header",
          }}
        >
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            My account
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Logout
          </MenuItem>
        </Menu>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) => navigate("/dashboard/accounts/retainer-invoices-add")}>
            New
          </Button>

          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform : "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RetainerInvoices;
