import { Button, Grid, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SouthWestIcon from "@mui/icons-material/SouthWest";

const columns = [
  { field: "date", headerName: "Date", width: 100 },
  { field: "branch", headerName: "Branch", width: 100 },
  { field: "payment", headerName: "Payment", width: 100 },
  { field: "type", headerName: "Type", width: 80 },
  { field: "refference", headerName: "Refference", width: 100 },
  { field: "customerName", headerName: "Customer Name", width: 120 },
  { field: "invoice", headerName: "Invoice",  width: 80 },
  { field: "amount", headerName: "Amount", type: "number",  width: 80 },
  { field: "unusedAmount", headerName: "Unused Amount", type: "number", width: 100 },
];

const rows = [
  {
    id: 1,
    date: "13 Jan 2024",
    branch: "Head Office",
    payment: "Payment-1",
    type: "Invoice",
    refference: "#112221",
    customerName: "Shami Manik",
    invoice:  "Invoice-1",
    amount: 10 ,
    unusedAmount: 0,
  },
  {
    id: 2,
    date: "13 Jan 2024",
    branch: "Head Office",
    payment: "Payment-1",
    type: "Invoice",
    refference: "#112221",
    customerName: "Shami Manik",
    invoice:  "Invoice-1",
    amount: 10 ,
    unusedAmount: 0,
  },
  {
    id: 3,
    date: "13 Jan 2024",
    branch: "Head Office",
    payment: "Payment-1",
    type: "Invoice",
    refference: "#112221",
    customerName: "Shami Manik",
    invoice:  "Invoice-1",
    amount: 10 ,
    unusedAmount: 0,
  },
  {
    id: 4,
    date: "13 Jan 2024",
    branch: "Head Office",
    payment: "Payment-1",
    type: "Invoice",
    refference: "#112221",
    customerName: "Shami Manik",
    invoice:  "Invoice-1",
    amount: 10 ,
    unusedAmount: 0,
  },
];

const PaymentReceived = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4
          className="cursor-pointer d-center"
          id="section-header"
          aria-controls={open ? "section-header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}>
          All Received Payments
          <ArrowDropDownIcon />
        </h4>
        <Menu
          id="section-header-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "section-header",
          }}
        >
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            My account
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Logout
          </MenuItem>
        </Menu>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) =>
              navigate("/dashboard/accounts/payment-received-add")}>
            New
          </Button>
          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform: "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentReceived;
