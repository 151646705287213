import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "date", headerName: "Date", width: 120 },
  { field: "journal", headerName: "Journal#", width: 150 },
  { field: "refferenceNumber", headerName: "Refference Number", width: 180 },
  { field: "status", headerName:  "Status", width: 100 },
  { field: "notes", headerName: "Notes",   width: 100 },
  { field: "amount", headerName: "Amount",  type: "number",  width: 100 },
  { field: "document", headerName: "Document",   width: 120 },
];

const rows = [
  {
    id: 1,
    date: "12 Oct 2024",
    journal: "Item 1 ",
    refferenceNumber: "--",
    status: "Draft",
    notes: "--" ,
    amount: 711,
    document: "-",
  },
  {
    id: 2,
    date: "12 Oct 2024",
    journal: "Item 1 ",
    refferenceNumber: "--",
    status: "Draft",
    notes: "--" ,
    amount: 711,
    document: "-",
  },
  {
    id: 3,
    date: "12 Oct 2024",
    journal: "Item 1 ",
    refferenceNumber: "--",
    status: "Draft",
    notes: "--" ,
    amount: 711,
    document: "-",
  },
  {
    id: 4,
    date: "12 Oct 2024",
    journal: "Item 1 ",
    refferenceNumber: "--",
    status: "Draft",
    notes: "--" ,
    amount: 711,
    document: "-",
  },
  {
    id: 5,
    date: "12 Oct 2024",
    journal: "Item 1 ",
    refferenceNumber: "--",
    status: "Draft",
    notes: "--" ,
    amount: 711,
    document: "-",
  },
];

const ManualJournals = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4
          className="cursor-pointer d-center">
          Manual Journals
        </h4>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) => navigate("/dashboard/accounts/manual-journals-add")}>
            New Journal
          </Button>

          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform : "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ManualJournals;