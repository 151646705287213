import React from "react";
import "../src/assets/css/allCss.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/micrositeCard.css";
import "../src/assets/css/mainLoader.css";
import "../src/assets/css/media.css";
import "../src/assets/css/lounge.css";


import 'suneditor/dist/css/suneditor.min.css';
import '@ashwamegh/react-link-preview/dist/index.css';



import { ReactSession } from "react-client-session";
import axios from "axios";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import { ChartStyle } from "./components/chart";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import ThemeColorPresets from "./components/ThemeColorPresets";
import ThemeLocalization from "./components/ThemeLocalization";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import {getSingleMicrositeDetailsUrl, userInfoUrl,} from "./layouts/dashboard/api";

// ----------------------------------------------------------------------
export default function App() {
  ReactSession.setStoreType("sessionStorage");
  const url = window.location.href;
  const urlParams = new URLSearchParams(url);
  const token = urlParams.get("t");
  const uuid = urlParams.get("u");

  // function for user details
  const getUserDetails = (getToken, getUuid) =>{
    let config = {
      method: 'get',
      url: userInfoUrl,
      headers: { 
        'Authorization': `Bearer ${getToken}`, 
      }
    };
    
    axios.request(config)
    .then((response) => {
      ReactSession.set("UserInfo", response.data);
      ReactSession.set("Token", response.data.t);
    })
  }

   // function for microsite details
   const getMicrositeDetails = (getUuid, getToken)=>{
    let config = {
      method: 'get',
      url: `${getSingleMicrositeDetailsUrl}/${getUuid}`,
    };

    axios.request(config)
    .then((response) => {
      ReactSession.set("MicrositeInfo", response.data);
    })
  }


  if(token !==null && uuid !==null){
    ReactSession.set("temp-token", token);
    ReactSession.set("temp-uuid", uuid);
    getMicrositeDetails(uuid,token);
    getUserDetails(token,uuid);
  }

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
