import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "src/layouts/dashboard/api";

export const mediaSlice = createApi({
  reducerPath: "media",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["media"],
  endpoints: (builder) => ({
    deleteMedia: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/cms/pages/${uuid}/media`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['media'],
    }),
  }),
});

export const {
    useDeleteMediaMutation
  } = mediaSlice;