// ----------------------------------------------------------------------
export const categoryPosition = [
  { code: 'no_position', label: 'No Position' },
  { code: 'main_nav', label: 'Main Menu' },
  { code: 'sub_nav1', label: 'Sub Menu 1'},
  { code: 'sub_nav2', label: 'Sub Menu 2'},
  { code: 'front_page_sections', label: 'Front Section' },
  { code: 'stiky_sections', label: 'Sticky Section' },
  { code: 'footer_nav', label: 'Footer Menu' },
  ];
  
