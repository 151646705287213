import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function ProjectAdd() {
  const navigate = useNavigate();

  // Repeat Mode Select
  const [repeatMode, setRepeatMode] = useState("");
  const handleRepeatMode = (event) => {
    setRepeatMode(event.target.value);
  };

  //  Payment Select
  const [payment, setPayment] = useState("");
  const handlePayment = (event) => {
    setPayment(event.target.value);
  };

  //  Slaes Person Select
  const [sales, setSales] = useState("");
  const handleSales = (event) => {
    setSales(event.target.value);
  };

  return (
    <>
      <div className="page-top">
        <h4> New Project </h4>
        <div className="page-top-buttons">
          <i onClick={(e) => navigate(-1)} className="cursor-pointer">
            <CloseIcon />
          </i>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Project Name"
                id="outlined-size-small"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Project Code"
                id="outlined-size-small"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                sx={{
                  fontSize: "12px",

                  ".MuiAutocomplete-option": {
                    fontSize: "14px !important",
                    margin: "2px 0 !important",
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Customer Name" fullWidth />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <FormControl fullWidth size="small">
                <InputLabel id="repeat-mode-select">Billing Method</InputLabel>
                <Select
                  labelId="repeat-mode-select"
                  id="repeat-mode-select-small"
                  value={repeatMode}
                  label="Repeat Mode"
                  onChange={handleRepeatMode}
                >
                  <MenuItem value="">
                    <em>
                      <small> None </small>
                    </em>
                  </MenuItem>
                  <MenuItem value={1}>
                    <small> Menu Item </small>
                  </MenuItem>
                  <MenuItem value={2}>
                    <small> Menu Item </small>
                  </MenuItem>
                  <MenuItem value={3}>
                    <small> Menu Item </small>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Description"
                id="outlined-size-small"
                size="small"
                fullWidth
                multiline
                rows={3}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="form-content">
        <h6 className="mb-10">
          <strong> Users </strong>
        </h6>
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th className="w-5">SL.</th>
                <th className="w-50">USER</th>
                <th className="w-45">EMAIL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-5">
                  <strong> 1 </strong>
                </td>
                <td className="w-50">
                  <Input
                    className="table-input-field"
                    type="text"
                    placeholder="User Name"
                    fullWidth
                  />
                </td>
                <td className="w-45">
                  <Input
                    className="table-input-field"
                    type="text"
                    placeholder="e-mail"
                    fullWidth
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="form-content">
        <h6 className="mb-10">
          <strong> Project Tasks </strong>
        </h6>
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th className="w-5">SL.</th>
                <th className="w-25">Task Name</th>
                <th className="w-40">Description</th>
                <th className="w-30 text-end">Billable</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="w-5">
                  <strong> 1 </strong>
                </td>
                <td className="w-30">
                  <Input
                    className="table-input-field"
                    type="text"
                    placeholder="Task Name"
                    fullWidth
                  />
                </td>
                <td className="w-35">
                  <Input
                    className="table-input-field"
                    type="text"
                    placeholder="Description"
                    fullWidth
                  />
                </td>
                <td className="w-30 text-end">00.00</td>
              </tr>
            </tbody>
          </table>
          <Button
            sx={{ mt: 1.5 }}
            size="small"
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add New Row
          </Button>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <div className="content-wrap">
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Terms & Conditions"
                  multiline
                  rows={3}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={5}>
            <h6> Attach File(s) to inventory adjustment </h6>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              sx={{ mt: 1 }}
            >
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="fixed-save-area">
        <Button variant="contained" size="small">
          Save
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          onClick={(e) => navigate(-1)}>
          Cancel
        </Button>
      </div>
    </>
  );
}

export default ProjectAdd;

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
];
