import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "src/layouts/dashboard/api";

export const acccountBalanceTransferSlice = createApi({
  reducerPath: "acccountBalanceTransfer",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["acccountBalanceTransfer"],
  endpoints: (builder) => ({
    getBalanceTransfer: builder.query({
      query: ({token,microsite_id,fromDate,toDate,fromAccount,toAccount}) => {
        return {
          url: `/api/common/balancetransfer?microsite_id=${microsite_id}&from_date=${fromDate}&to_date=${toDate}&from_account=${fromAccount}&to_account=${toAccount}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["acccountBalanceTransfer"],
    }),
    storeBalanceTransfer: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['acccountBalanceTransfer'],
    }),
    deleteBalanceTransfer: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/common/balancetransfer/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['acccountBalanceTransfer'],
    }),
  }),
});



export const {
    useGetBalanceTransferQuery,
    useStoreBalanceTransferMutation,
    useDeleteBalanceTransferMutation
  } = acccountBalanceTransferSlice;