import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "src/layouts/dashboard/api";

export const acccountBankSlice = createApi({
  reducerPath: "acccountBank",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["acccountBank"],
  endpoints: (builder) => ({
    getBanks: builder.query({
      query: ({token,microsite_id,accountNumber,keyword}) => {
        return {
          url: `/api/common/bankaccounts?microsite_id=${microsite_id}&account_number=${accountNumber}&keyword=${keyword}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["acccountBank"],
    }),
    storeBank: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['acccountBank'],
    }),
    deleteBank: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/common/bankaccounts/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['acccountBank'],
    }),
  }),
});

export const {
    useGetBanksQuery,
    useStoreBankMutation,
    useDeleteBankMutation
  } = acccountBankSlice;