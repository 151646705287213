import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "customerName", headerName: "Customer Name", width: 220 },
  { field: "projectName", headerName: "Project Name", width: 230 },
  { field: "billingMethod", headerName: "Billing Method", width: 230 },
  { field: "rate", headerName:  "Rate", width: 180 },
];

const rows = [
  {
    id: 1,
    customerName: "Hocking Memun",
    projectName: "EST-9405",
    billingMethod: "Bank",
    rate: "Stefan",
  },
  {
    id: 2,
    customerName: "Hocking Memun",
    projectName: "EST-9405",
    billingMethod: "Bank",
    rate: "Stefan",
  },
  {
    id: 3,
    customerName: "Hocking Memun",
    projectName: "EST-9405",
    billingMethod: "Bank",
    rate: "Stefan",
  },
  {
    id: 4,
    customerName: "Hocking Memun",
    projectName: "EST-9405",
    billingMethod: "Bank",
    rate: "Stefan",
  },
  {
    id: 5,
    customerName: "Hocking Memun",
    projectName: "EST-9405",
    billingMethod: "Bank",
    rate: "Stefan",
  },
];

const Projects = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4
          className="cursor-pointer d-center"
          id="section-header"
          aria-controls={open ? "section-header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}>
            
            All Projects <ArrowDropDownIcon />
        </h4>
        <Menu
          id="section-header-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 
            "aria-labelledby": "section-header",
          }}
        >
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            My account
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Logout
          </MenuItem>
        </Menu>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) => navigate("/dashboard/accounts/projects-add")}>
            New Project
          </Button>

          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform : "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Projects;