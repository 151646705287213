import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "date", headerName: "Date", width: 100 },
  { field: "project", headerName: "Project", width: 120 },
  { field: "customer", headerName: "Customer", width: 140 },
  { field: "task", headerName:  "Task", width: 140 },
  { field: "user", headerName:  "User", width: 160 },
  { field: "time", headerName:  "Time", width: 100 },
  { field: "billingStatus", headerName:  "Billing Status", width: 120 },
];

const rows = [
  {
    id: 1,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 2,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 3,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 4,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 5,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 6,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 7,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 8,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 9,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 10,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 11,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 12,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
  {
    id: 13,
    date: "12 Jun 2024",
    project: "EST-9405",
    customer: "Stefen",
    task: "reboot wireless application",
    user: "Teresa Miller DVM",
    time: "3:34",
    billingStatus: "Invoiced",
  },
];

const TimeSheet = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4
          className="cursor-pointer d-center"
          id="section-header"
          aria-controls={open ? "section-header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}>
            All Timesheets <ArrowDropDownIcon />
        </h4>
        <Menu
          id="section-header-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 
            "aria-labelledby": "section-header",
          }}>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            My account
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Logout
          </MenuItem>
        </Menu>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}>
            Log Time
          </Button>
         
          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform : "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TimeSheet;