import { Button } from "@mui/material";

const Bulk = () =>{
  
  return(
     <>
      <div className="bulk-center-contetn text-center">
          <h3> Bulk Update Accounts in Transactions </h3>
          <h5> Filter transactions (Invoices, Credit Notes, Purchase Orders, Expenses, Bills, Vendor Credits) and bulk-update its accounts with a new account</h5>
          <Button variant="contained" sx={{mt:2}}>Filter & Bulk Update</Button>
      </div>
     </>
  )

};

export default Bulk;

