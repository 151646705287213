// hooks
import useAuth from "../hooks/useAuth";
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar from "./Avatar";
import { ReactSession } from "react-client-session";
import { useState } from "react";
import { useEffect } from "react";
// ----------------------------------------------------------------------

export default function MyAvatar({ url,...other }) {
  ReactSession.setStoreType("sessionStorage");
  const { user } = useAuth();
  const [userAvater, setUserAvater] = useState("");
  const [userName, setUserName] = useState("");

  // useEffect(() => {
  //   const localData = ReactSession.get("UserInfo");

  //   const getAvatar = localData.profile.avatar;
  //   const getName = localData.profile.name;
  //   if (getAvatar != null) {
  //     const url = `http://icircles.app/${getAvatar}`;
  //     setUserAvater(url);
  //   }
  //   if (getName != null) {
  //     setUserName(getName);
  //   }
  // }, []);

  return (
    <Avatar
      src={url}
      alt={''}
      color={"default"}
      {...other}
    ></Avatar>
  );
}
