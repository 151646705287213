import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
// guards
import GuestGuard from "../guards/GuestGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import AddPriceList from "src/pages/dashboard/account/PriceList/AddPriceList";
import InventoryAdjustment from "src/pages/dashboard/account/InventoryAdjustment/InventoryAdjustment";
import AddAdjustment from "src/pages/dashboard/account/InventoryAdjustment/AddAdjustment";
import Banking from "src/pages/dashboard/account/Banking/Banking";
import AddBank from "src/pages/dashboard/account/Banking/AddBank";
import ImportStatement from "src/pages/dashboard/account/Banking/ImportStatement";
import Customers from "src/pages/dashboard/account/Sales/Customers";
import CustomerAdd from "src/pages/dashboard/account/Sales/CustomerAdd";
import Estimate from "src/pages/dashboard/account/Sales/Estimate";
import EstimateAdd from "src/pages/dashboard/account/Sales/EstimateAdd";
import RetainerInvoices from "src/pages/dashboard/account/Sales/RetainerInvoices";
import RetainerInvoicesAdd from "src/pages/dashboard/account/Sales/RetainerInvoicesAdd";
import SalesOrder from "src/pages/dashboard/account/Sales/SalesOrder";
import SalesOrderAdd from "src/pages/dashboard/account/Sales/SalesOrderAdd";
import DeliveryChallan from "src/pages/dashboard/account/Sales/DeliveryChallan";
import DeliveryChallanAdd from "src/pages/dashboard/account/Sales/DeliveryChallanAdd";
import Invoice from "src/pages/dashboard/account/Sales/Invoice";
import InvoiceAdd from "src/pages/dashboard/account/Sales/InvoiceAdd";
import PaymentReceived from "src/pages/dashboard/account/Sales/PaymentReceived";
import PaymentReceivedAdd from "src/pages/dashboard/account/Sales/PaymentReceivedAdd";
import RecurringInvoicesAdd from "src/pages/dashboard/account/Sales/RecurringInvoicesAdd";
import RecurringInvoices from "src/pages/dashboard/account/Sales/RecurringInvoices";
import AccountHome from "src/pages/dashboard/account/AccountHome/AccountHome";
import Vendors from "src/pages/dashboard/account/Purchases/Vendors";
import VendorsAdd from "src/pages/dashboard/account/Purchases/VendorsAdd";
import Expenses from "src/pages/dashboard/account/Purchases/Expenses";
import ExpensesAdd from "src/pages/dashboard/account/Purchases/ExpensesAdd";
import Recurring from "src/pages/dashboard/account/Purchases/Recurring";
import RecurringAdd from "src/pages/dashboard/account/Purchases/RecurringAdd";
import PurchaseOrder from "src/pages/dashboard/account/Purchases/PurchaseOrder";
import PurchaseOrderAdd from "src/pages/dashboard/account/Purchases/PurchaseOrderAdd";
import Bill from "src/pages/dashboard/account/Purchases/Bill";
import BillAdd from "src/pages/dashboard/account/Purchases/BillAdd";
import PaymentMade from "src/pages/dashboard/account/Purchases/PaymentMade";
import PaymentMadeAdd from "src/pages/dashboard/account/Purchases/PaymentMadeAdd";
import RecurringBill from "src/pages/dashboard/account/Purchases/RecurringBill";
import RecurringBillAdd from "src/pages/dashboard/account/Purchases/RecurringBillAdd";
import VendorCredit from "src/pages/dashboard/account/Purchases/VendorCredit";
import VendorCreditAdd from "src/pages/dashboard/account/Purchases/VendorCreditAdd";
import Projects from "src/pages/dashboard/account/TimeTracking/Projects";
import TimeSheet from "src/pages/dashboard/account/TimeTracking/TimeSheet";
import ManualJournals from "src/pages/dashboard/account/Accountant/ManualJournals";
import BulkUpdate from "src/pages/dashboard/account/Accountant/BulkUpdate";
import CurrencyAdjustment from "src/pages/dashboard/account/Accountant/CurrencyAdjustment";
import ChartOfAccounts from "src/pages/dashboard/account/Accountant/ChartOfAccounts";
import TransactionLocking from "src/pages/dashboard/account/Accountant/TransactionLocking";
import ProjectAdd from "src/pages/dashboard/account/TimeTracking/ProjectAdd";
import ManualJournalsAdd from "src/pages/dashboard/account/Accountant/ManualJournalsAdd";
import ChartOfAccountAdd from "src/pages/dashboard/account/Accountant/ChartOfAccountAdd";
// import QuizAnswerCreate from "src/pages/dashboard/quiz/quizAnswers/QuizAnswerCreate";
// import QuizAllParticipants from "src/pages/dashboard/quiz/QuizAllParticipants/QuizAllParticipants";
// import QuizQuestionUpdate from "src/pages/dashboard/quiz/QuizQuestionUpdate";
// import QuizQuestionCreate from "src/pages/dashboard/quiz/QuizQuestionCreate";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <UserRegistration />
            </GuestGuard>
          ),
        },
        {
          path: "microsites",
          element: (
            <GuestGuard>
              <Micrisites />
            </GuestGuard>
          ),
        },
        {
          path: "profile/:userid/:micrositeid",
          element: (
            <GuestGuard>
              <GeneralProfileMain />
            </GuestGuard>
          ),
        },
        {
          path: "webcard/:empid/:msid",
          element: (
            <GuestGuard>
              {/* <WebcardMain /> */}
              <PublicWebcard />
            </GuestGuard>
          ),
        },
        {
          path: "otp",
          element: (
            <GuestGuard>
              <Otp />
            </GuestGuard>
          ),
        },
      ],
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <RoleBasedGuard>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: "app", element: <GeneralApp /> },
        { path: "app", element: <LoungePage /> },
        { path: "ecommerce", element: <GeneralEcommerce /> },
        { path: "analytics", element: <GeneralAnalytics /> },
        { path: "banking", element: <GeneralBanking /> },
        { path: "booking", element: <GeneralBooking /> },

        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/dashboard/e-commerce/category" replace />,
              index: true,
            },
            { path: "shop", element: <EcommerceShop /> },
            { path: "product/:name", element: <EcommerceProductDetails /> },
            { path: "category", element: <EcommerceCategoryList /> },
            { path: "list", element: <EcommerceProductList /> },
            { path: "order", element: <EcommerceOrderList /> },
            { path: "brand", element: <EcommerceBrandList /> },
            { path: "product/webcard", element: <ProductWebcardView /> },
            { path: "newOrder", element: <EcommerceOrderCreate /> },
            { path: "orderDetails", element: <EcommerceOrderDetails /> },
            { path: "newBrand", element: <EcommerceBrandCreate /> },
            { path: "newCat", element: <EcommerceCategoryCreate /> },
            { path: "product/new", element: <EcommerceProductCreate /> },
            { path: "product/:name/edit", element: <EcommerceProductCreate /> },
            { path: "category/:name/edit", element: <EcommerceProductCreate />,},
            { path: "checkout", element: <EcommerceCheckout /> },
          ],
        },
        {
          path: "education",
          children: [
            {
              element: <Navigate to="/dashboard/education/students" replace />,
              index: true,
            },
            { path: "dashboard", element: <StudentsLists /> },
            { path: "students", element: <StudentsLists /> },
            { path: "student/create", element: <StudentCreate /> },
            { path: "student/edit", element: <StudentEdit /> },
            { path: "teachers", element: <TeacherLists /> },
            { path: "teacher/create", element: <TeacherCreate /> },
            { path: "teacher/edit", element: <TeacherEdit /> },
            { path: "stufs", element: <StufLists /> },
            { path: "stuf/create", element: <StufCreate /> },
            { path: "stuf/edit", element: <StuffEdit /> },
            { path: "stuf/edit", element: <StufCreate /> },
            { path: "managements", element: <ManagementList /> },
            { path: "management/create", element: <ManagementCreate /> },
            { path: "management/edit", element: <ManagementEdit /> },
            { path: "quizes", element: <QuizeLists /> },
            { path: "quize/create", element: <QuizeCreate /> },
          ],
        },
        {
          path: "medprofassociation",
          children: [
            {
              element: (
                <Navigate to="/dashboard/medprofassociation/member" replace />
              ),
              index: true,
            },
            { path: "member", element: <MediMemberLists /> },
            { path: "member/create", element: <MediMemberCreate /> },
            { path: "member/edit", element: <MediMemberEdit /> },
          ],
        },
        {
          path: "socialactivitysociety",
          children: [
            {
              element: (
                <Navigate
                  to="/dashboard/socialactivitysociety/member"
                  replace
                />
              ),
              index: true,
            },
            { path: "member", element: <MediMemberLists /> },
            { path: "member/create", element: <MediMemberCreate /> },
            { path: "member/edit", element: <MediMemberEdit /> },
          ],
        },
        // {
        //   path: "socialactivitysociety",
        //   children: [
        //     {
        //       element: <Navigate to="/dashboard/socialactivitysociety/member" replace />,
        //       index: true,
        //     },
        //     { path: "member", element: <SocialAactivitySocietyMemberLists /> },
        //     { path: "member/create", element: <SocialAactivitySocietyMemberCreate /> },
        //     { path: "member/edit", element: <SocialAactivitySocietyMemberEdit /> },
        //   ],
        // },
        {
          path: "pathology",
          children: [
            {
              element: <Navigate to="/dashboard/pathology/reports" replace />,
              index: true,
            },
            { path: "reports", element: <PathologyReportLists /> },
            { path: "reports/create", element: <PathologyReportCreate /> },
            { path: "sales", element: <PathologySaleList /> },
            { path: "sales/create", element: <PathologySalesCreate /> },
            { path: "tests", element: <PathologyTestList /> },
            { path: "tests/create", element: <PathologyTestCreate /> },
            { path: "category", element: <PathologyCategoryList /> },
            { path: "category/create", element: <PathologyCategoryCreate /> },
          ],
        },
        {
          path: "patients",
          children: [
            {
              element: <Navigate to="/dashboard/patients/list" replace />,
              index: true,
            },
            { path: "list", element: <PatientsList /> },
          ],
        },
        {
          path: "pharmacy",
          children: [
            {
              element: <Navigate to="/dashboard/pharmacy/dashboard" replace />,
              index: true,
            },
            { path: "dashboard", element: <PharmacyDashboard /> },
            { path: "category", element: <PharmacyCategoryList /> },
            { path: "category/create", element: <PharmacyCategoryCreate /> },
            { path: "brands", element: <PharmacyBrandList /> },
            { path: "brands/create", element: <PharmacyBrandCreate /> },
            { path: "products", element: <PharmacyProductList /> },
            { path: "products/create", element: <PharmacyProductCreate /> },
          ],
        },
        {
          path: "packages",
          children: [
            {
              element: <Navigate to="/dashboard/packages/list" replace />,
              index: true,
            },
            { path: "dashboard", element: <PackageDashboard /> },
            { path: "category", element: <PackageCategoryList /> },
            { path: "category/create", element: <PackageCategoryCreate /> },
            { path: "brands", element: <PackageBrandList /> },
            { path: "brands/create", element: <PackageBrandCreate /> },
            { path: "list", element: <PackageProductList /> },
            { path: "list/create", element: <PackageProductCreate /> },
          ],
        },
        {
          path: "vault",
          children: [
            {
              element: <Navigate to="/dashboard/vault/list" replace />,
              index: true,
            },

            { path: "list", element: <VaultAdvertisementList /> },
            { path: "new", element: <VaultAdvertisementCreate /> },
            { path: "vault/:name/edit", element: <VaultAdvertisementCreate /> },
          ],
        },
        {
          path: "cms",
          children: [
            // {
            //   element: <Navigate to="/dashboard/cms/list" replace />,
            //   index: true,
            // },
            {
              element: <Navigate to="/dashboard/cms/contentlist" replace />,
              index: true,
            },
            { path: "contentlist", element: <WebsiteContentCmsPosts /> },
            { path: "newcontentlist", element: <WebsiteContentNewCmsPost /> },
            {
              path: "content/:uuid/edit",
              element: <WebsiteContentWithCategoryEditPost />,
            },

            { path: "list", element: <WebsiteContentPosts /> },
            { path: "new", element: <WebsiteContentNewPost /> },
            { path: ":name/edit", element: <WebsiteContentPostEdit /> },
          ],
        },
        // chapter routes
        {
          path: "chapter",
          children: [
            // {
            //   element: <Navigate to="/dashboard/cms/list" replace />,
            //   index: true,
            // },
            {
              element: <Navigate to="/dashboard/chapter/list" replace />,
              index: true,
            },
            { path: "list", element: <ChapterLists /> },
            { path: "store", element: <NewChapter /> },
            { path: "bulk-store", element: <ChapterBulkUpload /> },
          ],
        },

        // quiz routes

        {
          path: "quiz",
          children: [
            {
              element: <Navigate to="/dashboard/quiz/list" replace />,
              index: true,
            },
            { path: "list", element: <QuizLists /> },
            { path: "questions/create", element: <QuizQuestionCreate /> },
            { path: "answers/create", element: <QuizAnswerCreate /> },
          ],
        },
        {
          path: "attendee",
          children: [
            {
              element: (
                <Navigate to="/dashboard/attendee/participants" replace />
              ),
              index: true,
            },
            { path: "participants", element: <QuizMemberLists /> },
            { path: "create", element: <QuizMemberCreate /> },
            { path: "update", element: <QuizMemberUpdate /> },
            // { path: "store", element: <NewChapter /> },
            // { path: "bulk-store", element: <ChapterBulkUpload /> },
          ],
        },
        // category
        {
          path: "category",
          children: [
            {
              element: <Navigate to="/dashboard/category/list" replace />,
              index: true,
            },
            { path: "list", element: <WebsiteContentCategoriess /> },
            { path: "new", element: <WebsiteContentNewCategory /> },
            { path: ":uuid/edit", element: <WebsiteContentEditCategory /> },
          ],
        },
        {
          path: "authors",
          children: [
            {
              element: <Navigate to="/dashboard/authors/list" replace />,
              index: true,
            },
            { path: "list", element: <WebsiteContentAuthors /> },
            { path: "new", element: <WebsiteContentNewAuthorsForm /> },
            { path: ":uuid/edit", element: <WebsiteContentEditAuthors /> },
          ],
        },
        {
          path: "settings",
          children: [
            {
              element: <Navigate to="/dashboard/settings/list" replace />,
              index: true,
            },
            { path: "list", element: <WebsiteContentSettingsAll /> },
            {
              path: "schoolSettings",
              element: <EducationalContentSettingsAll />,
            },
            { path: "medprofwebsettings", element: <MedProfWebSettingsAll /> },
            {
              path: "socialactivitysocietywebconfig",
              element: <SocialActivitySocietyWebconfigSettingsAll />,
            },
            { path: "class", element: <EducationClassList /> },
            { path: "class-create", element: <EducationClassCreate /> },
            { path: "section", element: <EducationSectionList /> },
            { path: "section-create", element: <EducationSectionCreate /> },
            { path: "shift", element: <EducationShiftList /> },
            { path: "shift-create", element: <EducationShiftCreate /> },
            {
              path: "microsites/webcard-settings",
              element: <WebcardSettings />,
            },
            {
              path: "emp/webcard-settings",
              element: <EmpWebcardSettings />,
            },
            {
              path: "webcard-view",
              element: <GenerateWebcardView />,
            },
            {
              path: "emp-webcard-view",
              element: <EmpWebcardView />,
            },
            {
              path: "emp-webhcard-view",
              element: <EmpWebcardHorizontalView />,
            },
          ],
        },
        {
          path: "microsite",
          children: [
            {
              element: <Navigate to="/dashboard/microsite/list" replace />,
              index: true,
            },
            { path: "list", element: <WebsiteContentMicrosite /> },
            { path: "new", element: <WebsiteContentNewMicrosite /> },
            { path: "edit", element: <WebsiteContentEditMicrosite /> },
          ],
        },
        {
          path: "hr",
          children: [
            {
              element: <Navigate to="/dashboard/hr/dashboard" replace />,
              index: true,
            },
            { path: "dashboard", element: <WebsiteContentHR /> },
            { path: "settings", element: <HrSettingsPage /> },
            { path: "employee", element: <EmployeList /> },
            { path: "employee-bulk", element: <EmployeBulkUpload /> },
            { path: "employee/bio", element: <HrProfileBio /> },
            { path: "employeeNew", element: <EmployeListCreate /> },
            { path: "employeeEdit", element: <EmployeeEdit /> },
            { path: "joblist", element: <JobLists /> },
            { path: "jobDetails/:uuid", element: <JobDetails /> },
            { path: "jobCreate", element: <JobCreate /> },
            { path: "leave", element: <LeaveMainPage /> },
            { path: "leave-type-create", element: <LeaveTypeCreatePage /> },
            {
              path: "leave-application/create",
              element: <LeaveApplicationCreatePage />,
            },
            {
              path: "leave-application/details",
              element: <LeaveApplicationHrDetailsPage />,
            },
            { path: "shift", element: <WorkshiftMainPage /> },
            { path: "shift/create", element: <WorkshiftCreatePage /> },
            { path: "notice", element: <NoticeListPage /> },
            { path: "notice/create", element: <NoticeCreatePage /> },
            { path: "notice/details", element: <NoticeDetailsPage /> },
            { path: "department", element: <DepartmentListPage /> },
            { path: "department/create", element: <DepartmentCreatePage /> },
            { path: "department/details", element: <DepartmentDetailsPage /> },

            { path: "attendence", element: <AttendenceHrListPage /> },
            { path: "attendence/create", element: <AttendenceCreatePage /> },

            { path: "daily-report", element: <DailyReportHR /> },


            { path: "calender", element: <CalenderHrMainPage /> },
            { path: "calender/manage", element: <CalenderHrManagePage /> },
            { path: "calender/create", element: <CalenderHrCreatePage /> },
            { path: "calender/details", element: <CalenderHrDetailsPage /> },

            // { path: "details", element: <WebsiteContentMembersDetails /> },
          ],
        },
        {
          path: "lounge",
          children: [
            {
              element: <Navigate to="/dashboard/lounge/professional" replace />,
              index: true,
            },
            { path: "professional", element: <LoungePage /> },
            // { path: "details", element: <WebsiteContentMembersDetails /> },
            { path: "details/:uuid", element: <LoungeDetailsPage /> },

          ],
        },
        {
          path: "accounts",
          children: [
            {
              element: <Navigate to="/dashboard/accounts/dashboard" replace />,
              index: true,
            },
            { path: "dashboard", element: <AccountDashBoard /> },

            // Account Home
            { path: "account-home", element: <AccountHome /> },

            //Order
            { path: "orders", element: <OrderLists /> },
            { path: "order-store", element: <StoreOrder /> },


            // Items
            { path: "items", element: <AccountItems /> },
            { path: "item-create", element: <AccountAddItem /> },
            { path: "price-list", element: <PriceList /> },
            { path: "add-price-list", element: <AddPriceList /> },
            { path: "inventory-adjustment", element: <InventoryAdjustment /> },
            { path: "add-inventory-adjustment", element: <AddAdjustment /> },

            // Bank Pages
            { path: "banking", element: <Banking /> },
            { path: "add-bank", element: <AddBank /> },
            { path: "import-statement", element: <ImportStatement/> },

            // Sales Pages
            { path: "customers", element: <Customers/> },
            { path: "customers-add", element: <CustomerAdd/> },
            { path: "estimate", element: <Estimate/> },
            { path: "estimate-add", element: <EstimateAdd/> },
            { path: "retainer-invoices", element: <RetainerInvoices/> },
            { path: "retainer-invoices-add", element: <RetainerInvoicesAdd/> },
            { path: "sales-order", element: <SalesOrder/> },
            { path: "sales-order-add", element: <SalesOrderAdd/> },
            { path: "delivery-challan", element: <DeliveryChallan/> },
            { path: "delivery-challan-add", element: <DeliveryChallanAdd/> },
            { path: "invoice", element: <Invoice/> },
            { path: "invoice-add", element: <InvoiceAdd/> },
            { path: "payment-received", element: <PaymentReceived/> },
            { path: "payment-received-add", element: <PaymentReceivedAdd/> },
            { path: "recurring-invoices", element: <RecurringInvoices/> },
            { path: "recurring-invoices-add", element: <RecurringInvoicesAdd/> },

            // Purchases Pages
            { path: "vendors", element: <Vendors/> },
            { path: "vendors-add", element: <VendorsAdd/> },
            { path: "expenses", element: <Expenses/> },
            { path: "expenses-add", element: <ExpensesAdd/> },
            { path: "recurring-expenses", element: <Recurring/> },
            { path: "recurring-expenses-add", element: <RecurringAdd/> },
            { path: "purchase-orders", element: <PurchaseOrder/> },
            { path: "purchase-orders-add", element: <PurchaseOrderAdd/> },
            { path: "bills", element: <Bill/> },
            { path: "bills-add", element: <BillAdd/> },
            { path: "payment-made", element: <PaymentMade/> },
            { path: "payment-made-add", element: <PaymentMadeAdd/> },
            { path: "recurring-bills", element: <RecurringBill/> },
            { path: "recurring-bills-add", element: <RecurringBillAdd/> },

            // Time Tracking
            { path: "projects", element: <Projects/> },
            { path: "projects-add", element: <ProjectAdd/> },
            { path: "time-sheet", element: <TimeSheet/> },

            // Accountant
            { path: "manual-journals", element: <ManualJournals/> },
            { path: "manual-journals-add", element: <ManualJournalsAdd/> },
            { path: "bulk-update", element: <BulkUpdate/> },
            { path: "currency-adjustments", element: <CurrencyAdjustment/> },
            { path: "chart-of-account", element: <ChartOfAccounts/> },
            { path: "chart-of-account-add", element: <ChartOfAccountAdd/> },
            { path: "transaction-locking", element: <TransactionLocking/> },




            { path: "banks", element: <BankLists /> },
            { path: "bank-store", element: <StoreBank /> },
            { path: "bank-details", element: <BankDetailsPage /> },
            { path: "balancetransfer", element: <BalanceTransferLists /> },
            {
              path: "balancetransfer-store",
              element: <StoreBalanceTransfer />,
            },
            // { path: "details", element: <WebsiteContentMembersDetails /> },
          ],
        },
        {
          path: "crm",
          children: [
            {
              element: <Navigate to="/dashboard/crm/cutomerList" replace />,
              index: true,
            },
            { path: "cutomerList", element: <CrmCustomerList /> },
            // { path: "details", element: <WebsiteContentMembersDetails /> },
          ],
        },
        {
          path: "report",
          children: [
            {
              element: <Navigate to="/dashboard/report/list" replace />,
              index: true,
            },
            { path: "list", element: <Report /> },
            // { path: "details", element: <WebsiteContentMembersDetails /> },
          ],
        },
        {
          path: "media",
          children: [
            {
              element: <Navigate to="/dashboard/media/all" replace />,
              index: true,
            },
            { path: "all", element: <MediaPage /> },
            { path: "files/:name", element: <MediaNestedPage /> },
          ],
        },
        {
          path: "member",
          children: [
            {
              element: <Navigate to="/dashboard/member/list" replace />,
              index: true,
            },
            { path: "list", element: <WebsiteContentMembers /> },
            { path: "details", element: <WebsiteContentMembersDetails /> },
            { path: "bulk-store", element: <MemberBulkUpload /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/list" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfile /> },
            { path: "profile-bio", element: <ProfileBio /> },
            {
              path: "profile-bio/leave-application",
              element: <UserLeaveApplicationPage />,
            },
            {
              path: "profile-bio/leave-application/create",
              element: <LeaveApplicationCreateBioPage />,
            },
            {
              path: "profile-bio/leave-application/details",
              element: <LeaveApplicationBioDetailsPage />,
            },

            { path: "profile-bio/notice", element: <NoticeListBioPage /> },

            { path: "attendence", element: <AttendenceGivePage /> },
            { path: "attendence/details", element: <AttendenceDetailsPage /> },
            { path: "attendence/list", element: <AttendencelistPage /> },

            { path: "cards", element: <UserCards /> },
            { path: "list", element: <UserList /> },
            { path: "new", element: <UserCreate /> },
            { path: "invite-user", element: <UserInvite /> },
            { path: ":name/edit", element: <UserCreate /> },
            { path: "account", element: <UserAccountEdit /> },

            { path: "calender", element: <CalenderUserMainPage /> },
            { path: "daily-report", element: <DailyReport /> },
            { path: "daily-report-store", element: <DailyReportStore /> },

          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceList /> },
            { path: ":id", element: <InvoiceDetails /> },
            { path: ":id/edit", element: <InvoiceEdit /> },
            { path: "new", element: <InvoiceCreate /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/posts" replace />,
              index: true,
            },
            { path: "posts", element: <BlogPosts /> },
            { path: "post/:title", element: <BlogPost /> },
            { path: "new", element: <BlogNewPost /> },
          ],
        },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <Mail /> },
            { path: "label/:customLabel/:mailId", element: <Mail /> },
            { path: ":systemLabel", element: <Mail /> },
            { path: ":systemLabel/:mailId", element: <Mail /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "new", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },
        { path: "calendar", element: <Calendar /> },
        { path: "kanban", element: <Kanban /> },
        { path: "kanban-store", element: <KanbanStore /> },
        { path: "kanban-info", element: <KanbanInfo /> },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard" replace /> },
  ]);
}

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const GeneralEcommerce = Loadable(
  lazy(() => import("../pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBooking"))
);

//socialactivitysociety
const SocialAactivitySocietyMemberLists = Loadable(
  lazy(() =>
    import("../pages/dashboard/socialactivitysociety/member/MemberList")
  )
);
const SocialAactivitySocietyMemberCreate = Loadable(
  lazy(() =>
    import("../pages/dashboard/socialactivitysociety/member/MemberCreate")
  )
);
const SocialAactivitySocietyMemberEdit = Loadable(
  lazy(() =>
    import("../pages/dashboard/socialactivitysociety/member/MemberEdit")
  )
);

//medprofassociation
const MediMemberLists = Loadable(
  lazy(() => import("../pages/dashboard/medprofassociation/member/MemberList"))
);
const MediMemberCreate = Loadable(
  lazy(() => import("../pages/dashboard/medprofassociation/member/MeberCreate"))
);
const MediMemberEdit = Loadable(
  lazy(() => import("../pages/dashboard/medprofassociation/member/MemberEdit"))
);

//education
const StudentsLists = Loadable(
  lazy(() => import("../pages/dashboard/education/student/StudentList"))
);
const StudentCreate = Loadable(
  lazy(() => import("../pages/dashboard/education/student/StudentCreate"))
);
const StudentEdit = Loadable(
  lazy(() => import("../pages/dashboard/education/student/StudentEdit"))
);
const QuizeLists = Loadable(
  lazy(() => import("../pages/dashboard/education/quize/QuizeList"))
);
const QuizeCreate = Loadable(
  lazy(() => import("../pages/dashboard/education/quize/QuizeCreate.js"))
);
const TeacherLists = Loadable(
  lazy(() => import("../pages/dashboard/education/teacher/TeacherList"))
);
const TeacherCreate = Loadable(
  lazy(() => import("../pages/dashboard/education/teacher/TeacherCreate"))
);
const TeacherEdit = Loadable(
  lazy(() => import("../pages/dashboard/education/teacher/TeacherEdit"))
);
const StufLists = Loadable(
  lazy(() => import("../pages/dashboard/education/stuff/StuffList"))
);
const StufCreate = Loadable(
  lazy(() => import("../pages/dashboard/education/stuff/StuffCreate"))
);
const StuffEdit = Loadable(
  lazy(() => import("../pages/dashboard/education/stuff/StuffEdit"))
);
const ManagementList = Loadable(
  lazy(() => import("../pages/dashboard/education/management/ManagementList"))
);
const ManagementCreate = Loadable(
  lazy(() => import("../pages/dashboard/education/management/ManagementCreate"))
);
const ManagementEdit = Loadable(
  lazy(() => import("../pages/dashboard/education/management/ManagementEdit"))
);

//Pathology
const PathologyReportLists = Loadable(
  lazy(() =>
    import("../pages/dashboard/medical/pathology/PathologyReportLists")
  )
);
const PathologySaleList = Loadable(
  lazy(() => import("../pages/dashboard/medical/pathology/PathologySaleList"))
);
const PathologySalesCreate = Loadable(
  lazy(() =>
    import("../pages/dashboard/medical/pathology/PathologySalesCreate")
  )
);
const PathologyReportCreate = Loadable(
  lazy(() =>
    import("../pages/dashboard/medical/pathology/PathologyReportCreate")
  )
);
const PathologyTestList = Loadable(
  lazy(() => import("../pages/dashboard/medical/pathology/PathologyTestList"))
);
const PathologyTestCreate = Loadable(
  lazy(() => import("../pages/dashboard/medical/pathology/PathologyTestCreate"))
);
const PathologyCategoryList = Loadable(
  lazy(() =>
    import("../pages/dashboard/medical/pathology/PathologyCategoryList")
  )
);
const PathologyCategoryCreate = Loadable(
  lazy(() =>
    import("../pages/dashboard/medical/pathology/PathologyCategoryCreate")
  )
);

//Patients
const PatientsList = Loadable(
  lazy(() => import("../pages/dashboard/medical/patients/PatientsList"))
);

//Pharmacy
const PharmacyDashboard = Loadable(
  lazy(() => import("../pages/dashboard/medical/pharmacy/PharmacyDashboard"))
);
const PharmacyCategoryList = Loadable(
  lazy(() => import("../pages/dashboard/medical/pharmacy/PharmacyCategoryList"))
);
const PharmacyCategoryCreate = Loadable(
  lazy(() =>
    import("../pages/dashboard/medical/pharmacy/PharmacyCategoryCreate")
  )
);
const PharmacyBrandList = Loadable(
  lazy(() => import("../pages/dashboard/medical/pharmacy/PharmacyBrandList"))
);
const PharmacyBrandCreate = Loadable(
  lazy(() => import("../pages/dashboard/medical/pharmacy/PharmacyBrandCreate"))
);
const PharmacyProductList = Loadable(
  lazy(() => import("../pages/dashboard/medical/pharmacy/PharmacyProductList"))
);
const PharmacyProductCreate = Loadable(
  lazy(() =>
    import("../pages/dashboard/medical/pharmacy/PharmacyProductCreate")
  )
);

//Package
const PackageDashboard = Loadable(
  lazy(() => import("../pages/dashboard/medical/package/PackageDashboard"))
);
const PackageCategoryList = Loadable(
  lazy(() => import("../pages/dashboard/medical/package/PackageCategoryList"))
);
const PackageCategoryCreate = Loadable(
  lazy(() => import("../pages/dashboard/medical/package/PackageCategoryCreate"))
);
const PackageBrandList = Loadable(
  lazy(() => import("../pages/dashboard/medical/package/PackageBrandList"))
);
const PackageBrandCreate = Loadable(
  lazy(() => import("../pages/dashboard/medical/package/PackageBrandCreate"))
);
const PackageProductList = Loadable(
  lazy(() => import("../pages/dashboard/medical/package/PackageProductList"))
);
const PackageProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/medical/package/PackageProductCreate"))
);

// ECOMMERCE
const EcommerceShop = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceShop"))
);
const EcommerceProductDetails = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductDetails"))
);
const EcommerceOrderDetails = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceOrderDetails"))
);
const EcommerceCategoryList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCategoryList"))
);
const EcommerceBrandList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceBrandList"))
);
const ProductWebcardView = Loadable(
  lazy(() => import("../pages/auth/Settings/ProductWebcardView"))
);
const EcommerceOrderList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceOrderList"))
);
const EcommerceProductList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductList"))
);

const EcommerceCategoryCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCategoryCreate"))
);
const EcommerceOrderCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceOrderCreate"))
);
const EcommerceBrandCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceBrandCreate"))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductCreate"))
);
const EcommerceCheckout = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCheckout"))
);

// vault advertisement
const VaultAdvertisementList = Loadable(
  lazy(() => import("../pages/dashboard/VaultAdvertisementList"))
);
const VaultAdvertisementCreate = Loadable(
  lazy(() => import("../pages/dashboard/VaultAdvertisementCreate"))
);

// INVOICE
const InvoiceList = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceList"))
);
const InvoiceDetails = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceDetails"))
);
const InvoiceCreate = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceCreate"))
);
const InvoiceEdit = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceEdit"))
);

// CMS
const WebsiteContentPosts = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithoutCategory/Content/WebsiteContentPosts"
    )
  )
);
const WebsiteContentCmsPosts = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithCategory/Content/WebsiteContentCmsPosts"
    )
  )
);
const WebsiteContentNewCmsPost = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithCategory/Content/WebsiteContentNewCmsPost"
    )
  )
);
const WebsiteContentWithCategoryEditPost = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithCategory/Content/WebsiteContentPostEdit"
    )
  )
);
const WebsiteContentNewPost = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithoutCategory/Content/WebsiteContentNewPost"
    )
  )
);
const WebsiteContentPostEdit = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithoutCategory/Content/WebsiteContentPostEdit"
    )
  )
);

// Chapter
const ChapterLists = Loadable(
  lazy(() => import("../pages/dashboard/chapter/ChapterLists"))
);
const NewChapter = Loadable(
  lazy(() => import("../pages/dashboard/chapter/NewChapter"))
);
const ChapterBulkUpload = Loadable(
  lazy(() => import("../pages/dashboard/BulkUpload/ChapterBulkUpload"))
);

// quiz
const QuizLists = Loadable(
  lazy(() => import("../pages/dashboard/quiz/QuizLists"))
);
const QuizQuestionCreate = Loadable(
  lazy(() => import("../pages/dashboard/quiz/QuizQuestionCreate"))
);
const QuizAnswerCreate = Loadable(
  lazy(() => import("../pages/dashboard/quiz/QuizAnswerCreate"))
);
const QuizMemberLists = Loadable(
  lazy(() => import("../pages/dashboard/quiz/participents/MemberList"))
);
const QuizMemberCreate = Loadable(
  lazy(() => import("../pages/dashboard/quiz/participents/MeberCreate"))
);
const QuizMemberUpdate = Loadable(
  lazy(() => import("../pages/dashboard/quiz/participents/MemberEdit"))
);

// const NewChapter = Loadable(
//   lazy(() => import("../pages/dashboard/chapter/NewChapter"))
// );
// const ChapterBulkUpload = Loadable(
//   lazy(() => import("../pages/dashboard/chapter/ChapterBulkUpload"))
// );

// Categories
const WebsiteContentCategoriess = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithCategory/Category/WebsiteContentCategoriess"
    )
  )
);
const WebsiteContentNewCategory = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithCategory/Category/WebsiteContentNewCategory"
    )
  )
);
const WebsiteContentEditCategory = Loadable(
  lazy(() =>
    import(
      "../pages/dashboard/contentManagement/ContentWithCategory/Category/WebsiteContentEditCategory"
    )
  )
);

// Authors
const WebsiteContentAuthors = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentAuthors"))
);
const WebsiteContentNewAuthorsForm = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentNewAuthors"))
);
const WebsiteContentEditAuthors = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentEditAuthors"))
);

//settings
const WebsiteContentSettingsAll = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentSettingsAll"))
);
const EducationalContentSettingsAll = Loadable(
  lazy(() => import("../pages/dashboard/EducationalContentSettingsAll"))
);
const MedProfWebSettingsAll = Loadable(
  lazy(() => import("../pages/dashboard/MedProfWebSettingsAll"))
);
const SocialActivitySocietyWebconfigSettingsAll = Loadable(
  lazy(() =>
    import("../pages/dashboard/SocialActivitySocietyWebconfigSettingsAll")
  )
);
const EducationClassList = Loadable(
  lazy(() => import("../pages/dashboard/education/class/EducationClassList"))
);
const EducationClassCreate = Loadable(
  lazy(() => import("../pages/dashboard/education/class/EducationClassCreate"))
);
const EducationSectionList = Loadable(
  lazy(() =>
    import("../pages/dashboard/education/section/EducationSectionList")
  )
);
const EducationShiftList = Loadable(
  lazy(() => import("../pages/dashboard/education/shift/EducationShiftList"))
);
const EducationSectionCreate = Loadable(
  lazy(() =>
    import("../pages/dashboard/education/section/EducationSectionCreate")
  )
);
const EducationShiftCreate = Loadable(
  lazy(() => import("../pages/dashboard/education/shift/EducationShiftCreate"))
);

const EmpWebcardSettings = Loadable(
  lazy(() => import("../pages/auth/Settings/EmpWebcardSettings"))
);
const GenerateWebcardView = Loadable(
  lazy(() => import("../pages/auth/Settings/GenerateWebcardView"))
);
const EmpWebcardView = Loadable(
  lazy(() => import("../pages/auth/Settings/EmpWebcardView"))
);
const EmpWebcardHorizontalView = Loadable(
  lazy(() => import("../pages/auth/Settings/EmpWebcardHorizontalView"))
);
const WebcardSettings = Loadable(
  lazy(() => import("../pages/auth/Settings/WebcardSettings"))
);

// Microsites
const WebsiteContentMicrosite = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentMicrosites"))
);
const WebsiteContentNewMicrosite = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentNewMicrosite"))
);
const WebsiteContentEditMicrosite = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentEditMicrosite"))
);

// BLOG
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/BlogPosts")));
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/BlogPost")));
const BlogNewPost = Loadable(
  lazy(() => import("../pages/dashboard/BlogNewPost"))
);

// HR
const WebsiteContentHR = Loadable(
  lazy(() => import("../pages/dashboard/hr/WebsiteContentHR"))
);
const HrSettingsPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/settings/HrSettingsPage"))
);
const EmployeList = Loadable(
  lazy(() => import("../pages/dashboard/hr/employee/EmployeList"))
);
const EmployeBulkUpload = Loadable(
  lazy(() => import("../pages/dashboard/BulkUpload/EmployeeBulkUpload"))
);
const HrProfileBio = Loadable(
  lazy(() => import("../pages/dashboard/hr/bio/HrProfileBio"))
);

const EmployeListCreate = Loadable(
  lazy(() => import("../pages/dashboard/hr/employee/EmployeeCreate"))
);
const EmployeeEdit = Loadable(
  lazy(() => import("../pages/dashboard/hr/employee/EmployeeEdit"))
);
const JobLists = Loadable(
  lazy(() => import("../pages/dashboard/hr/job/JobLists"))
);
const JobDetails = Loadable(
  lazy(() => import("../pages/dashboard/hr/job/JobDetails"))
);
const JobCreate = Loadable(
  lazy(() => import("../pages/dashboard/hr/job/JobCreate"))
);
const AttendenceHrListPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/attendence/AttendenceHrListPage"))
);
const AttendenceCreatePage = Loadable(
  lazy(() => import("../pages/dashboard/hr/attendence/AttendenceCreatePage"))
);

const DailyReportHR = Loadable(
  lazy(() => import("../pages/dashboard/hr/dailyReport/DailyReportHR"))
);

const CalenderHrMainPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/calender/CalenderHrMainPage"))
);
const CalenderHrManagePage = Loadable(
  lazy(() => import("../pages/dashboard/hr/calender/CalenderHrManagePage"))
);
const CalenderHrCreatePage = Loadable(
  lazy(() => import("../pages/dashboard/hr/calender/CalenderHrCreatePage"))
);
const CalenderHrDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/calender/CalenderHrDetailsPage"))
);

const LeaveMainPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/leave/LeaveMainPage"))
);
const LeaveTypeCreatePage = Loadable(
  lazy(() => import("../pages/dashboard/hr/leave/LeaveTypeCreatePage"))
);
const LeaveApplicationCreatePage = Loadable(
  lazy(() => import("../pages/dashboard/hr/leave/LeaveApplicationCreatePage"))
);
const LeaveApplicationHrDetailsPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/hr/leave/LeaveApplicationHrDetailsPage")
  )
);
const WorkshiftMainPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/workshift/WorkshiftMainPage"))
);
const WorkshiftCreatePage = Loadable(
  lazy(() => import("../pages/dashboard/hr/workshift/WorkshiftCreatePage"))
);
const NoticeListBioPage = Loadable(
  lazy(() => import("../pages/dashboard/bio/notice/NoticeListBioPage"))
);

const NoticeCreatePage = Loadable(
  lazy(() => import("../pages/dashboard/hr/notice/NoticeCreatePage"))
);
const NoticeDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/notice/NoticeDetailsPage"))
);
const DepartmentListPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/department/DepartmentListPage"))
);
const DepartmentCreatePage = Loadable(
  lazy(() => import("../pages/dashboard/hr/department/DepartmentCreatePage"))
);
const DepartmentDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/department/DepartmentDetailsPage"))
);

// Lounge
const LoungePage = Loadable(
  lazy(() => import("../pages/dashboard/lounge/LoungePage"))
);
const LoungeDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/lounge/LoungeDetailsPage"))
);

//Account
const AccountDashBoard = Loadable(
  lazy(() => import("../pages/dashboard/account/AccountDashBoard"))
);
const AccountItems = Loadable(
  lazy(() => import("../pages/dashboard/account/Items/Items"))
);
const AccountAddItem = Loadable(
  lazy(() => import("../pages/dashboard/account/Items/AddItem"))
);
const PriceList = Loadable(
  lazy(() => import("../pages/dashboard/account/PriceList/PriceList"))
);
// Chapter
const BankLists = Loadable(
  lazy(() => import("../pages/dashboard/account/Banks/BankLists"))
);
const StoreBank = Loadable(
  lazy(() => import("../pages/dashboard/account/Banks/StoreBank"))
);
const OrderLists = Loadable(
  lazy(() => import("../pages/dashboard/account/Orders/OrderLists"))
);
const StoreOrder = Loadable(
  lazy(() => import("../pages/dashboard/account/Orders/StoreOrder"))
);
const BankDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/account/Banks/BankDetailsPage"))
);
const BalanceTransferLists = Loadable(
  lazy(() =>
    import("../pages/dashboard/account/BalanceTransfer/BalanceTransferLists")
  )
);
const StoreBalanceTransfer = Loadable(
  lazy(() =>
    import("../pages/dashboard/account/BalanceTransfer/StoreBalanceTransfer")
  )
);

//CRM
const CrmCustomerList = Loadable(
  lazy(() => import("../pages/dashboard/crm/CrmCustomerList"))
);

//Report
const Report = Loadable(lazy(() => import("../pages/dashboard/report/Report")));

// Members
const WebsiteContentMembers = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentMembers"))
);
const WebsiteContentMembersDetails = Loadable(
  lazy(() => import("../pages/dashboard/WebsiteContentMembersDetails"))
);
const MemberBulkUpload = Loadable(
  lazy(() => import("../pages/dashboard/BulkUpload/MemberBulkUpload"))
);

// USER
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));
const UserAccountEdit = Loadable(
  lazy(() => import("../pages/dashboard/UserEdit"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/UserCreate"))
);
const UserInvite = Loadable(
  lazy(() => import("../pages/dashboard/UserInvite"))
);
const UserRegistration = Loadable(
  lazy(() => import("../pages/dashboard/UserRegistration"))
);

const AttendenceGivePage = Loadable(
  lazy(() => import("../pages/dashboard/bio/attendence/AttendenceGivePage"))
);
const AttendenceDetailsPage = Loadable(
  lazy(() => import("../pages/dashboard/bio/attendence/AttendenceDetailsPage"))
);
const AttendencelistPage = Loadable(
  lazy(() => import("../pages/dashboard/bio/attendence/AttendencelistPage"))
);
const CalenderUserMainPage = Loadable(
  lazy(() => import("../pages/dashboard/bio/calender/CalenderUserMainPage"))
);
const DailyReport = Loadable(
  lazy(() => import("../pages/dashboard/bio/dailyReport/DailyReport"))
);
const DailyReportStore = Loadable(
  lazy(() => import("../pages/dashboard/bio/dailyReport/DailyReportStore"))
);

// // Media
// const Media = Loadable(
//   lazy(() => import("../pages/dashboard/Media"))
// );
// Media
const MediaPage = Loadable(
  lazy(() => import("../pages/dashboard/media/MediaPage"))
);
const MediaNestedPage = Loadable(
  lazy(() => import("../pages/dashboard/media/MediaNestedPage"))
);

// APP
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")));
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/kanban/Kanban")));
const KanbanStore = Loadable(lazy(() => import("../pages/dashboard/kanban/KanbanStore")));
const KanbanInfo = Loadable(lazy(() => import("../pages/dashboard/kanban/KanbanInfo")));

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Micrisites = Loadable(lazy(() => import("../pages/auth/Microsites")));
const WebcardMain = Loadable(
  lazy(() => import("../pages/auth/WebCard/webcardmain"))
);
const GeneralProfileMain = Loadable(
  lazy(() => import("../pages/auth/GeneralProfile/GeneralProfileMain"))
);
const ProfileBio = Loadable(
  lazy(() => import("../pages/dashboard/bio/ProfileBio"))
);
const UserLeaveApplicationPage = Loadable(
  lazy(() => import("../pages/dashboard/bio/leave/UserLeaveApplicationPage"))
);
const LeaveApplicationCreateBioPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/bio/leave/LeaveApplicationCreateBioPage")
  )
);
const LeaveApplicationBioDetailsPage = Loadable(
  lazy(() =>
    import("../pages/dashboard/bio/leave/LeaveApplicationBioDetailsPage")
  )
);

const NoticeListPage = Loadable(
  lazy(() => import("../pages/dashboard/hr/notice/NoticeListPage"))
);

const Otp = Loadable(lazy(() => import("../pages/auth/Otp")));

//Public
const PublicWebcard = Loadable(
  lazy(() => import("../pages/public/PublicEmpWecardPage"))
);
