import React, { useState } from "react";
import AddchartIcon from "@mui/icons-material/Addchart";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BarChart, LineChart, PieChart } from "@mui/x-charts";
import { AiFillHtml5 } from "react-icons/ai";

function AccountHome() {
  const [tabValue, setTabValue] = useState("1");
  const handleChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  // Menu 1
  const [menu1, setMenu1] = useState(null);
  const open = Boolean(menu1);
  const handleClick = (event) => {
    setMenu1(event.currentTarget);
  };
  const handleClose = () => {
    setMenu1(null);
  };

  // Menu 2
  const [menu2, setMenu2] = useState(null);
  const open2 = Boolean(menu2);
  const handleClick2 = (event) => {
    setMenu2(event.currentTarget);
  };
  const handleClose2 = () => {
    setMenu2(null);
  };

  return (
    <>
      <TabContext value={tabValue}>
        <div className="account-home-top">
          <div className="home-top-info-wrap">
            <div>
              <i>
                <AddchartIcon sx={{ color: "#929292" }} />
              </i>
            </div>
            <div className="home-top-info">
              <h5>
                <strong> Hello, Demo User </strong>
              </h5>
              <h6> Demo Org </h6>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Dashboard" value="1" />
              <Tab label="Getting Started" value="2" />
              <Tab label="Announcements" value="3" />
            </TabList>
          </Box>
        </div>

        <div className="home-bottom-info-wrap">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabPanel value="1">
              <Grid container spacing={2} sx={{ mt: 0.2 }}>
                <Grid item xs={6}>
                  <div className="account-card-item">
                    <div className="account-card-top">
                      <h5>
                        <strong> Total Receivables </strong>
                      </h5>
                      <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        size="small"
                        id="section-header"
                        aria-controls={open ? "section-header-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        New
                      </Button>
                      <Menu
                        id="section-header-menu"
                        anchorEl={menu1}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "section-header",
                        }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          sx={{ fontSize: "13px" }}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          sx={{ fontSize: "13px" }}
                        >
                          My account
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          sx={{ fontSize: "13px" }}
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                    </div>
                    <Box sx={{ width: "100%", mt: 1.7 }}>
                      <LinearProgress
                        variant="determinate"
                        value={75}
                        className="account-card-lineProgress"
                      />
                    </Box>
                    <div className="account-card-bottom">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <h6> CURRENT </h6>
                          <h4>
                            <strong> $0.00 </strong>
                          </h4>
                        </Grid>
                        <Grid item xs={6}>
                          <h6> OVERDUE </h6>
                          <h4>
                            <strong> $372,580.0 </strong>
                          </h4>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="account-card-item">
                    <div className="account-card-top">
                      <h5>
                        <strong> Total Payables </strong>
                      </h5>
                      <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        size="small"
                        id="section-header"
                        aria-controls={
                          open2 ? "section-header-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open2 ? "true" : undefined}
                        onClick={handleClick2}
                      >
                        New
                      </Button>
                      <Menu
                        id="section-header-menu"
                        anchorEl={menu2}
                        open={open2}
                        onClose={handleClose2}
                        MenuListProps={{
                          "aria-labelledby": "section-header",
                        }}
                      >
                        <MenuItem
                          onClick={handleClose2}
                          sx={{ fontSize: "13px" }}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose2}
                          sx={{ fontSize: "13px" }}
                        >
                          My account
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose2}
                          sx={{ fontSize: "13px" }}
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                    </div>
                    <Box sx={{ width: "100%", mt: 1.7 }}>
                      <LinearProgress
                        variant="determinate"
                        value={75}
                        className="account-card-lineProgress"
                      />
                    </Box>
                    <div className="account-card-bottom">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <h6> CURRENT </h6>
                          <h4>
                            <strong> $5320.00 </strong>
                          </h4>
                        </Grid>
                        <Grid item xs={6}>
                          <h6> OVERDUE </h6>
                          <h4>
                            <strong> $242,580.0 </strong>
                          </h4>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="flow-content">
                <div className="flow-content-top">
                  <h5>
                    <strong> Cash Flow </strong>
                  </h5>
                  <div
                    className="flow-menu"
                    id="section-header"
                    aria-controls={open2 ? "section-header-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick2}
                  >
                    This Fiscal Year
                    <i>
                      <ExpandMoreIcon
                        sx={{ fontSize: "18px", lineHeight: "0px" }}
                      />
                    </i>
                  </div>
                  <Menu
                    id="section-header-menu"
                    anchorEl={menu2}
                    open={open2}
                    onClose={handleClose2}
                    MenuListProps={{
                      "aria-labelledby": "section-header",
                    }}
                  >
                    <MenuItem onClick={handleClose2} sx={{ fontSize: "13px" }}>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={handleClose2} sx={{ fontSize: "13px" }}>
                      My account
                    </MenuItem>
                    <MenuItem onClick={handleClose2} sx={{ fontSize: "13px" }}>
                      Logout
                    </MenuItem>
                  </Menu>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <LineChart
                      xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
                      series={[
                        {
                          data: [2, 5.5, 2, 8.5, 1.5, 5, 5.5, 6.5, 8.5, 9.5],
                          area: true,
                        },
                      ]}
                      height={400}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="flow-info-wrapper">
                      <div className="flow-info-wrap mt-10 text-end">
                        <h6> Cash as on 01/01/2024 </h6>
                        <h4>
                          <strong> $13,56,598.65 </strong>
                        </h4>
                      </div>
                      <div className="flow-info-wrap mt-20 text-end">
                        <h6 className="color-green"> Incoming </h6>
                        <h4>
                          <strong> $2,32,53,901.42 + </strong>
                        </h4>
                      </div>
                      <div className="flow-info-wrap mt-20 text-end">
                        <h6 className="color-red"> Outgoing </h6>
                        <h4>
                          <strong> $2,58,026.46 - </strong>
                        </h4>
                      </div>
                      <div className="flow-info-wrap mt-30 text-end">
                        <h6> Cash as on 31/12/2024 </h6>
                        <h4>
                          <strong> $15,56,598.35 </strong>
                        </h4>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="flow-content">
                    <div className="flow-content-top">
                      <h5>
                        <strong> Income and Expense </strong>
                      </h5>
                    </div>

                    <div className="flow-info-wrapper">
                      <BarChart
                        xAxis={[
                          {
                            scaleType: "band",
                            data: [
                              "Jan",
                              "Feb",
                              "Mar",
                              "Apr",
                              "May",
                              "Jun",
                              "Jul",
                              "Aug",
                              "Sep",
                              "Oct",
                              "Nov",
                            ],
                          },
                        ]}
                        series={[
                          { data: [4, 3, 5, 2, 3, 4, 5, 4, 3, 5, 2] },
                          { data: [1, 6, 3, 4, 2, 5, 3, 2, 6, 3, 4] },
                        ]}
                        height={280}
                      />
                      <Grid container spacing={2} sx={{ mb: 1 }}>
                        <Grid item xs={6}>
                          <div className="flow-info-wrap">
                            <h6 className="color-green"> Total Income </h6>
                            <h4>
                              <strong> $13,56,598.65 </strong>
                            </h4>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="flow-info-wrap">
                            <h6 className="color-red"> Total Expenses </h6>
                            <h4>
                              <strong> $15,56,598.35 </strong>
                            </h4>
                          </div>
                        </Grid>
                      </Grid>
                      <p>
                        * Income and expense values displayed are exclusive of
                        taxes.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flow-content">
                    <div className="flow-content-top">
                      <h5>
                        <strong> Top Expenses </strong>
                      </h5>
                    </div>
                    <div className="flow-pie-chart">
                      <PieChart
                        series={[
                          {
                            data: [
                              { id: 0, value: 10, label: "(44.62%)" },
                              { id: 1, value: 15, label: "(18.66%" },
                              { id: 2, value: 20, label: "(12.78%)" },
                              { id: 3, value: 25, label: "(9.76%)" },
                              { id: 4, value: 12, label: "(5.79%)" },
                              { id: 5, value: 19, label: "(8.39%)" },
                            ],
                          },
                        ]}
                        height={200}
                        sx={{
                          text: {
                            fontSize: "13px !important",
                            fontWeight: "400 !important",
                          },
                          // '.MuiChartsLegend-mark':{
                          //     height: "10px !important",
                          //     width: "10px !important",
                          // }
                        }}
                      />
                      <h6 className="mt-20">
                        * Income and expense values displayed are exclusive of
                        taxes.
                      </h6>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="flow-content">
                    <div className="flow-content-top">
                      <h5>
                        <strong> Projects </strong>( Unbilled Hours )
                      </h5>
                    </div>
                    <div className="flow-info-wrapper">
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <Avatar sx={{ width: 60, height: 60 }}>No</Avatar>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="flow-info-wrap">
                            <h6> Kate Bergstrom </h6>
                            <h6>
                              <strong> Refined Plastic Pants </strong>
                            </h6>
                          </div>
                        </Grid>
                        <Grid item xs={4} className="text-center">
                          <Button size="small" variant="outlined">
                            No Budget Hours
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <Avatar sx={{ width: 60, height: 60 }}>No</Avatar>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="flow-info-wrap">
                            <h6> Kate Bergstrom </h6>
                            <h6>
                              <strong> Refined Plastic Pants </strong>
                            </h6>
                          </div>
                        </Grid>
                        <Grid item xs={4} className="text-center">
                          <Button size="small" variant="outlined">
                            No Budget Hours
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <Avatar sx={{ width: 60, height: 60 }}>No</Avatar>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="flow-info-wrap">
                            <h6> Kate Bergstrom </h6>
                            <h6>
                              <strong> Refined Plastic Pants </strong>
                            </h6>
                          </div>
                        </Grid>
                        <Grid item xs={4} className="text-center">
                          <Button size="small" variant="outlined">
                            No Budget Hours
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="flow-content-bottom">
                      <h6 className="color-green">
                        <strong> Show All Project </strong>
                      </h6>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flow-content">
                    <div className="flow-content-top">
                      <h5>
                        <strong> Bank and Credit Cards </strong>
                      </h5>
                    </div>
                    <div className="flow-info-wrapper">
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <h6>Bank Account</h6>
                        </Grid>
                        <Grid item xs={4} className="text-end">
                          <h6>
                            <strong> $182782 </strong>
                          </h6>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1.5 }} />
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <h6>Cash Account</h6>
                        </Grid>
                        <Grid item xs={4} className="text-end">
                          <h6>
                            <strong> $3272 </strong>
                          </h6>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1.5 }} />
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <h6>Credit Card Account</h6>
                        </Grid>
                        <Grid item xs={4} className="text-end">
                          <h6>
                            <strong> $282782 </strong>
                          </h6>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1.5 }} />
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <h6>Payment Clearing Account</h6>
                        </Grid>
                        <Grid item xs={4} className="text-end">
                          <h6>
                            <strong> $82782 </strong>
                          </h6>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1.5 }} />
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <h6>Cash Account</h6>
                        </Grid>
                        <Grid item xs={4} className="text-end">
                          <h6>
                            <strong> $2782 </strong>
                          </h6>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1.5 }} />
                      <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1 }}
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <h6> Clearing Account</h6>
                        </Grid>
                        <Grid item xs={4} className="text-end">
                          <h6>
                            <strong> $82782 </strong>
                          </h6>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2"> 
               <h2> Getting Start </h2>
             </TabPanel>
            <TabPanel value="3">
               <h2> Announcment </h2>
            </TabPanel>
          </Box>
        </div>
      </TabContext>
    </>
  );
}

export default AccountHome;
