import { Button, Grid, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SouthWestIcon from "@mui/icons-material/SouthWest";

const columns = [
  { field: "date", headerName: "Date", width: 100 },
  { field: "invoice", headerName: "Invoice", width: 100 },
  { field: "orderNumber", headerName: "Order Number", width: 100 },
  { field: "customerName", headerName: "Customer Name", width: 120 },
  { field: "status", headerName: "Status", width: 100 },
  { field: "dueDate", headerName: "Due Date", width: 120 },
  { field: "amount", headerName: "Amount", type: "number", width: 120 },
  { field: "balanceDue", headerName: "Balance Due", type: "number", width: 120 },
];

const rows = [
  {
    id: 1,
    date: "16 Jan 2024",
    invoice: "Invoice1",
    orderNumber: "--",
    customerName: "Kate Bergstrom",
    status: "Draft",
    dueDate: "11 Jan 2024",
    amount:  1277,
    balanceDue: 790,
  },
  {
    id: 2,
    date: "16 Jan 2024",
    invoice: "Invoice1",
    orderNumber: "--",
    customerName: "Kate Bergstrom",
    status: "Draft",
    dueDate: "11 Jan 2024",
    amount:  1277,
    balanceDue: 790,
  },
  {
    id: 3,
    date: "16 Jan 2024",
    invoice: "Invoice1",
    orderNumber: "--",
    customerName: "Kate Bergstrom",
    status: "Draft",
    dueDate: "11 Jan 2024",
    amount:  1277,
    balanceDue: 790,
  },
  {
    id: 4,
    date: "16 Jan 2024",
    invoice: "Invoice1",
    orderNumber: "--",
    customerName: "Kate Bergstrom",
    status: "Draft",
    dueDate: "11 Jan 2024",
    amount:  1277,
    balanceDue: 790,
  },
  {
    id: 5,
    date: "16 Jan 2024",
    invoice: "Invoice1",
    orderNumber: "--",
    customerName: "Kate Bergstrom",
    status: "Draft",
    dueDate: "11 Jan 2024",
    amount:  1277,
    balanceDue: 790,
  },
];

const Invoice = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="page-top">
        <h4
          className="cursor-pointer d-center"
          id="section-header"
          aria-controls={open ? "section-header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          All Invoices
          <ArrowDropDownIcon />
        </h4>
        <Menu
          id="section-header-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "section-header",
          }}
        >
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            My account
          </MenuItem>
          <MenuItem onClick={handleClose} sx={{ fontSize: "13px" }}>
            Logout
          </MenuItem>
        </Menu>
        <div className="page-top-buttons">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={(e) =>
              navigate("/dashboard/accounts/invoice-add")}>
            New
          </Button>
          <Button variant="outlined" className="icon-button">
            <MoreVertIcon />
          </Button>
        </div>
      </div>

      <div className="invoice-payment-summury">
        <h6>
          <strong> Pyament Summary </strong>
        </h6>

        <div className="invoice-payment-summury-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className="invoice-payment-summury-wrap">
                <div className="payment-summury-icon">
                  <i>
                    <SouthWestIcon sx={{ color: "white" }} />
                  </i>
                </div>
                <div className="payment-summury-content">
                  <h6> Total Outstanding Receivables </h6>
                  <h5>
                    <strong> $672.19 </strong>
                  </h5>
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="invoice-payment-summury-wrap">
                <div className="payment-summury-content">
                  <h6> Due Today </h6>
                  <h5>
                    <strong> $0.00 </strong>
                  </h5>
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
            <div className="invoice-payment-summury-wrap">
                <div className="payment-summury-content">
                  <h6> Due Within 30 Days </h6>
                  <h5>
                    <strong> $0.00 </strong>
                  </h5>
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
            <div className="invoice-payment-summury-wrap">
                <div className="payment-summury-content">
                  <h6> Overdue Invoice </h6>
                  <h5>
                    <strong> $672.19 </strong>
                  </h5>
                </div>
              </div>
            </Grid>
            <Grid item xs={3}>
            <div className="invoice-payment-summury-wrap">
                <div className="payment-summury-content">
                  <h6> Average No. of Days for Getting Paid </h6>
                  <h5>
                    <strong> 0 Days </strong>
                  </h5>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="page-content">
        <div className="table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "13px !important",
                textTransform: "uppercase",
              },
              ".MuiDataGrid-cellContent": {
                fontSize: "13px !important",
                fontWeight: "500 !important",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Invoice;
