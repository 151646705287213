import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Avatar, Box, Link, Typography } from "@mui/material";
// hooks
import useAuth from "../../../hooks/useAuth";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import MyAvatar from "../../../components/MyAvatar";
import { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  ReactSession.setStoreType("sessionStorage");
  const { user } = useAuth();
  const [userAvater, setUserAvater] = useState("");
  const [userName, setUserName] = useState("");
  const [designation, setDesignation] = useState("");

  useEffect(() => {
    const localData = ReactSession.get("UserInfo");
    if (!localData) window.location.reload = "/auth/login";

    const getAvatar = localData.profile.avatar;
    const getName = localData.profile.name;

    const getMSInfo = ReactSession.get("MicrositeInfo");
    if (!getMSInfo) window.location.reload = "/auth/login";
    const getUserDetails = getMSInfo.user_details.user_details;
    const userDetails = JSON.parse(getUserDetails);

    if (getAvatar != null) {
      const url = `http://icircles.app/${getAvatar}`;
      setUserAvater(url);
    }
    if (userDetails.hasOwnProperty("name")) {
      setUserName(userDetails.name);
    }
    if (userDetails.hasOwnProperty("designation")) {
      setDesignation(userDetails.designation);
    }
  }, []);

  return (
    <Link
      underline="none"
      color="inherit"
      component={RouterLink}
      to={PATH_DASHBOARD.user.profile}
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: "transparent",
          }),
        }}
      >
        <Avatar alt={userName} src={userAvater} />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" sx={{ fontSize: 11 }}>
            {userName}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: 10 }}>
            ({designation})
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {user?.role}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
