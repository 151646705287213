import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

function AddPriceList() {
  const navigate = useNavigate();
  // Account Select
  const [account, setAccount] = useState("");
  const handleAccount = (event) => {
    setAccount(event.target.value);
  };



  return (
    <>
      <div className="page-top">
        <h4> New Price List </h4>
        <div className="page-top-buttons">
          <i onClick={(e) => navigate(-1)} className="cursor-pointer">
            <CloseIcon />
          </i>
        </div>
      </div>

      <div className="form-content">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="content-wrap">
              <TextField
                label="Name"
                id="outlined-size-small"
                size="small"
                fullWidth
              />
            </div>

            <div className="content-wrap">
              <FormControl sx={{ mt: 2 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Price List Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <div className="radio-card">
                    <FormControlLabel
                      value="allItems"
                      control={<Radio />}
                      label="All Items"
                    />
                  </div>
                  <div className="radio-card">
                    <FormControlLabel
                      value="individualItems"
                      control={<Radio />}
                      label="Individual Items"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>

            <div className="content-wrap">
              <FormControl sx={{ mt: 1.2}} fullWidth>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={3}/>
              </FormControl>
            </div>
            <div className="content-wrap">
              <TextField
                label="Percentage %"
                id="outlined-size-small"
                size="small"
                fullWidth
                type="number"
                sx={{mt:1}}
              />
            </div>
            <div className="content-wrap">
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <InputLabel id="account-select">Round Off To</InputLabel>
                <Select
                  labelId="account-select"
                  id="account-select-small"
                  value={account}
                  label="Account"
                  onChange={handleAccount}
                >
                  <MenuItem value="">
                  <em> <small> None </small> </em>
                  </MenuItem>
                  <MenuItem value={1}> <small> Menu Item </small> </MenuItem>
                  <MenuItem value={2}> <small> Menu Item </small> </MenuItem>
                  <MenuItem value={3}> <small> Menu Item </small> </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="fixed-save-area">
        <Button variant="contained" size="small">
          Save
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
          onClick={(e) => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default AddPriceList;
