// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
import { ReactSession } from "react-client-session";
// components
import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";
import { faL } from "@fortawesome/free-solid-svg-icons";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
};

var ecommercefind = false

var allNavs = [];

var navConfig2 = [];
ReactSession.setStoreType("sessionStorage");
const localData = JSON.parse(sessionStorage.getItem("__react_session__"));
if (localData !== null && localData.MicrositeInfo) {
  const MicrositeInfo = localData.MicrositeInfo.microsite_info;
  const UserInfo = localData.UserInfo;
  if (MicrositeInfo) {
    let microsite_meta = MicrositeInfo.subtype.meta;
    let menu = microsite_meta.hasOwnProperty("menu") ? microsite_meta.menu : "";

    if (menu) {
      menu.map((data, key) => {
        // console.log('menu name check', data)
        console.log('menus', data)
        // if(data.menu_parameter==='hr' && (UserInfo.id===19 || UserInfo.id===1256)){
        //   navConfig2.push({
        //     title: data.menu_name,
        //     path: "/dashboard/" + data.menu_parameter,
        //   });
        // }else{
        //   if(data.menu_parameter !=='hr'){
        //     navConfig2.push({
        //       title: data.menu_name,
        //       path: "/dashboard/" + data.menu_parameter,
        //     });
        //   }
        // }

        // console.log('sds', data?.menu_name)

        if(data?.menu_parameter ==='e-commerce'){
         ecommercefind = true
        }
        if(data?.menu_parameter !=='e-commerce' && data?.menu_parameter.split('/')[0] !=='e-commerce'){
          navConfig2.push({
            title: data.menu_name,
            path: "/dashboard/" + data.menu_parameter,
          });
        }
      });
    }
  }
}

// {
//   title: 'e-commerce',
//   path: PATH_DASHBOARD.eCommerce.root,
//   icon: ICONS.cart,
//   children: [
//     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
//     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
//     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
//     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
//     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
//     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
//   ],
// },

var ecommerce = [];
var items = [];
var banking = [];
var sales = [];
var purchases = []; 
var timeTracking = [];
var accountant = []; 

console.log('ecommerce find', ecommercefind)

// ecommerce elements 
if(ecommercefind ===true){
  items.push({
    title: 'E-commerce',
    path: "/dashboard/e-commerce",
    children:[
      {title: 'Category',  path: "/dashboard/e-commerce/category"},
      {title: 'Products', path: "/dashboard/e-commerce/list"},
      {title: 'Order', path: "/dashboard/e-commerce/order"},
      {title: 'Brand', path: "/dashboard/e-commerce/brand"},
    ]
  })
}
// items elements 
    items.push({
      title: 'Items',
      path: "/dashboard/accounts/items",
      children:[
        {title: 'Items',  path: "/dashboard/accounts/items"},
        {title: 'Price Lists', path: "/dashboard/accounts/price-list"},
        {title: 'Inventory Adjustments', path: "/dashboard/accounts/inventory-adjustment"},
      ]
    })
// banking elements
    banking.push(
      {title: "Banking",path: "/dashboard/accounts/banking"}
    )
//  sales elements
    sales.push({
        title: "Sales",
        path: "/dashboard/accounts/customers",
        children:[
          {title: "Customers",path: "/dashboard/accounts/customers"},
          {title: "Estimates",path: "/dashboard/accounts/estimate"},
          {title: "Retainer Invoices",path: "/dashboard/accounts/retainer-invoices"},
          {title: "Sales Order",path: "/dashboard/accounts/sales-order"},
          {title: "Delivery Challans",path: "/dashboard/accounts/delivery-challan"},
          {title: "Invoices",path: "/dashboard/accounts/invoice"},
          {title: "Payments Received",path: "/dashboard/accounts/payment-received"},
          {title: "Recurring Invoices",path: "/dashboard/accounts/recurring-invoices"},
        ]
    }) 

// Purchases elements
    purchases.push({
        title: "Purchases",
        path: "/dashboard/accounts/vendors",
        children:[
          {title: "Vendors",path: "/dashboard/accounts/vendors"},
          {title: "Expenses",path: "/dashboard/accounts/expenses"},
          {title: "Recurring Expenses",path: "/dashboard/accounts/recurring-expenses"},
          {title: "Purchase Orders",path: "/dashboard/accounts/purchase-orders"},
          {title: "Bills",path: "/dashboard/accounts/bills"},
          {title: "Payment Made",path: "/dashboard/accounts/payment-made"},
          {title: "Recurring Bills",path: "/dashboard/accounts/recurring-bills"},
        ]
    })

//  Time tracking elements
    timeTracking.push({
        title: "Time Tracking",
        path: "/dashboard/accounts/projects",
        children:[
          {title: "Projects",path: "/dashboard/accounts/projects"},
          {title: "Time Sheet",path: "/dashboard/accounts/time-sheet"},
        ]
    })

 // Accountant elements
    accountant.push({
        title: "Accountant",
        path: "/dashboard/accounts/manual-journals",
        children:[
          {title: "Manual Journals",path: "/dashboard/accounts/manual-journals"},
          {title: "Bulk Update", path: "/dashboard/accounts/bulk-update"},
          {title: "Currency Adjustments",path: "/dashboard/accounts/currency-adjustments"},
          {title: "Chart Of Account",path: "/dashboard/accounts/chart-of-account"},
          {title: "Transaction Locking",path: "/dashboard/accounts/transaction-locking"},
        ]
    })    
    
var navConfig3 = [];
navConfig3.push(
  {
    title: "Accounts",
    path: "/dashboard/accounts/dashboard",
  },
  {
    title: "Orders",
    path: "/dashboard/accounts/orders",
  },
  {
    title: "Accounts Home",
    path: "/dashboard/accounts/account-home",
  },
  // {
  //   title: "Items",
  //   path: "/dashboard/accounts/items",
  // },
  // {
  //   title: "Price Lists",
  //   path: "/dashboard/accounts/price-list",
  // },
  // {
  //   title: "Inventory Adjustments",
  //   path: "/dashboard/accounts/inventory-adjustment",
  // },


  // {
  //   title: "Bnaking",
  //   path: "/dashboard/accounts/banking",
  // },


  // {
  //   title: "Customers",
  //   path: "/dashboard/accounts/customers",
  // },
  // {
  //   title: "Estimates",
  //   path: "/dashboard/accounts/estimate",
  // },
  // {
  //   title: "Retainer Invoices",
  //   path: "/dashboard/accounts/retainer-invoices",
  // },
  // {
  //   title: "Sales Order",
  //   path: "/dashboard/accounts/sales-order",
  // },
  // {
  //   title: "Delivery Challans",
  //   path: "/dashboard/accounts/delivery-challan",
  // },
  // {
  //   title: "Invoices",
  //   path: "/dashboard/accounts/invoice",
  // },
  // {
  //   title: "Payments Received",
  //   path: "/dashboard/accounts/payment-received",
  // },
  // {
  //   title: "Recurring Invoices",
  //   path: "/dashboard/accounts/recurring-invoices",
  // },


  // {
  //   title: "Vendors",
  //   path: "/dashboard/accounts/vendors",
  // },
  // {
  //   title: "Expenses",
  //   path: "/dashboard/accounts/expenses",
  // },
  // {
  //   title: "Recurring Expenses",
  //   path: "/dashboard/accounts/recurring-expenses",
  // },
  // {
  //   title: "Purchase Orders",
  //   path: "/dashboard/accounts/purchase-orders",
  // },
  // {
  //   title: "Bills",
  //   path: "/dashboard/accounts/bills",
  // },
  // {
  //   title: "Payment Made",
  //   path: "/dashboard/accounts/payment-made",
  // },
  // {
  //   title: "Recurring Bills",
  //   path: "/dashboard/accounts/recurring-bills",
  // },


  // {
  //   title: "Projects",
  //   path: "/dashboard/accounts/projects",
  // },
  // {
  //   title: "Time Sheet",
  //   path: "/dashboard/accounts/time-sheet",
  // },


  // {
  //   title: "Manual Journals",
  //   path: "/dashboard/accounts/manual-journals",
  // },
  // {
  //   title: "Bulk Update",
  //   path: "/dashboard/accounts/bulk-update",
  // },
  // {
  //   title: "Currency Adjustments",
  //   path: "/dashboard/accounts/currency-adjustments",
  // },
  // {
  //   title: "Chart Of Account",
  //   path: "/dashboard/accounts/chart-of-account",
  // },
  // {
  //   title: "Transaction Locking",
  //   path: "/dashboard/accounts/transaction-locking",
  // },
);

allNavs =  Array.prototype.concat.apply([], [navConfig2, items, banking, sales, purchases,timeTracking, accountant]); 


const navConfig = [
  {
    items: allNavs,
  },
  // {
  //   items: navConfig3,
  // },
];

export default navConfig;
