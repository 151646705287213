import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem, Tooltip, IconButton } from "@mui/material";
// routes
import { PATH_DASHBOARD, PATH_AUTH } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import MyAvatar from "../../../components/MyAvatar";
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";
import Avatar from '@mui/material/Avatar';
import { attendenceUrl, baseUrl } from "../api";
import axios from "axios";
import { errorNotify, successNotify } from "src/components/notify";
import { ToastContainer } from "react-toastify";
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    linkTo: "/",
  },
  {
    label: "My Posts",
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: "My Company Profile",
    linkTo: '/dashboard/user/profile-bio',
  },
  {
    label: "Update Microsite",
    linkTo: PATH_DASHBOARD.microsite.edit,
  },
  {
    label: "My Microsites",
    linkTo: "/auth/microsites",
  },
  {
    label: "Webcard Settings",
    linkTo: "/dashboard/settings/microsites/webcard-settings",
  },
  {
    label: "Company Webcard",
    linkTo: "/dashboard/settings/webcard-view",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  ReactSession.setStoreType("sessionStorage");
  const msDetails = ReactSession.get('MicrositeInfo').microsite_info;
  const token = ReactSession.get('Token');
  const userInfo = ReactSession.get('MicrositeInfo')?.user_details;
  const userDetails = ReactSession.get('UserInfo');
  const location = useLocation();
  const [loaderVisible, setLoaderVisible] = useState(false)
  const [timer, setTimer] = useState(0)
  const [accessAttendence, setAccessAttendence] = useState(false)
  


  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    ReactSession.set("MicrositeInfo", "");
    ReactSession.set("UserInfo", "");
    ReactSession.set("menu", "");
    ReactSession.set("permission", "");
    window.location.href = "/auth/login";
  };


  useEffect(()=>{
    const allUserType =  userInfo?.user_type.split(',')
    if(allUserType){
      allUserType.forEach(element => {
        if(element ==='employee'){
          setAccessAttendence(true)
        }
      });
    }
 
  },[])

  const handleAttendence = ()=>{
        let data = new FormData();
        data.append('microsite_id', msDetails.id);
        // data.append('user_id', userDetails.id);

        let config = {
          method: 'post',
          url:  attendenceUrl,
          data : data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios.request(config)
        .then((response) => {
          successNotify('Success')
        })
        .catch(function (error) {
          if (error.response) {
            // console.log(error.response.data?.errors?.message[0]);
            errorNotify(error.response.data?.errors?.message[0], 5000)
            // console.log(error.response.status);
            // console.log(error.response.headers);
      }
   });
  }

  return (
    <>
    {/* <Avatar className="cursor-pointer" alt="Attendence" src="/static/images/avatar/1.jpg" onClick={(e)=> navigate('/dashboard/user/attendence')} /> */}
    {accessAttendence === true &&  <div class="attendence" onClick={(e)=>handleAttendence()}>
        <div class="pulse">Clock</div>
      </div>}

      {msDetails && msDetails?.website && msDetails?.website !=='null' && 
        <IconButton>
        <Avatar className="cursor-pointer" alt="M" src="">
          <a target="_blank" href={`${msDetails?.website}`}><LanguageRoundedIcon/></a>
        </Avatar>
        </IconButton>
      }
      
      <IconButton>
          <Avatar className="cursor-pointer" alt="M" src="">
            <a target="_blank" href={`${baseUrl}/microsite/${msDetails.uuid}`}><PreviewRoundedIcon/></a>
          </Avatar>
      </IconButton>
      
      <IconButton>
        <Avatar className="cursor-pointer" alt="Webcard" src="/static/images/avatar/1.jpg" onClick={(e)=> navigate('/dashboard/settings/emp-webcard-view',{state:{ id:userInfo?.id,userId:userInfo?.user_id,uuid:userInfo?.uuid}})} />
      </IconButton>  

      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
      <ToastContainer />
    </>
  );
}
