import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "src/layouts/dashboard/api";

export const medProMemberSlice = createApi({
  reducerPath: "medProMember",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["medProMember"],
  endpoints: (builder) => ({
    getAllMember: builder.query({
      query: ({token,microsite_id,type,currentPage,searchValue}) => {
        return {
          url: `/api/massociation/members/${microsite_id}?type=${type}&current_page=${currentPage}&keyword=${searchValue}`,
          method: "GET",
          headers:{
            Authorization: `Bearer ${token}`
          }
        };
      },
      providesTags: ["medProMember"],
    }),
    storeMember: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['medProMember'],
    }),
    storeBulkMember: builder.mutation({
      query: ({token,formData,url}) => ({
        url: url,
        method: 'POST',
        body: formData,
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['medProMember'],
    }),
    deleteMember: builder.mutation({
      query: ({token,uuid}) => ({
        url: `/api/common/user/${uuid}`,
        method: 'DELETE',
        headers:{
          Authorization: `Bearer ${token}`
        }
      }),
      invalidatesTags: ['medProMember'],
    }),
  }),
});

export const {
    useGetAllMemberQuery,
    useStoreMemberMutation,
    useStoreBulkMemberMutation,
    useDeleteMemberMutation
  } = medProMemberSlice;