// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/lounge"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  cms: {
    root: path(ROOTS_DASHBOARD, "/cms"),
    new: path(ROOTS_DASHBOARD, "/cms/new"),
    newcontent: path(ROOTS_DASHBOARD, "/cms/newcontentlist"),
    list: path(ROOTS_DASHBOARD, "/cms/list"),
    edit: (name) => path(ROOTS_DASHBOARD, `/cms/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/cms/reece-chung/edit`),
  },
  category: {
    root: path(ROOTS_DASHBOARD, "/category"),
    new: path(ROOTS_DASHBOARD, "/category/new"),
    list: path(ROOTS_DASHBOARD, "/category/list"),
    edit: (name) => path(ROOTS_DASHBOARD, `/category/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/cms/reece-chung/edit`),
  },
  authors: {
    root: path(ROOTS_DASHBOARD, "/authors"),
    new: path(ROOTS_DASHBOARD, "/authors/new"),
    list: path(ROOTS_DASHBOARD, "/authors/list"),
    edit: (name) => path(ROOTS_DASHBOARD, `/authors/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/authors/reece-chung/edit`),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, "/settings"),
    new: path(ROOTS_DASHBOARD, "/settings/new"),
    list: path(ROOTS_DASHBOARD, "/settings/list"),
    edit: (name) => path(ROOTS_DASHBOARD, `/settings/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/settin gs/reece-chung/edit`),
  },
  member: {
    root: path(ROOTS_DASHBOARD, "/member"),
    new: path(ROOTS_DASHBOARD, "/member/new"),
    list: path(ROOTS_DASHBOARD, "/member/list"),
    details: path(ROOTS_DASHBOARD, "/member/details"),
    edit: (name) => path(ROOTS_DASHBOARD, `/member/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/member/reece-chung/edit`),
  },
  microsite: {
    root: path(ROOTS_DASHBOARD, "/microsite"),
    new: path(ROOTS_DASHBOARD, "/microsite/new"),
    list: path(ROOTS_DASHBOARD, "/microsite/list"),
    edit: path(ROOTS_DASHBOARD, `/microsite/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/microsite/reece-chung/edit`),
  },
  media: {
    root: path(ROOTS_DASHBOARD, "/media"),
    // new: path(ROOTS_DASHBOARD, "/microsite/new"),
    // list: path(ROOTS_DASHBOARD, "/microsite/list"),
    // edit: path(ROOTS_DASHBOARD, `/microsite/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/microsite/reece-chung/edit`),
  },
  hr: {
    root: path(ROOTS_DASHBOARD, "/hr"),
    employee: path(ROOTS_DASHBOARD, "hr/employee"),
    employeeNew: path(ROOTS_DASHBOARD, "hr/employee"),
    // new: path(ROOTS_DASHBOARD, "/microsite/new"),
    // list: path(ROOTS_DASHBOARD, "/microsite/list"),
    // edit: path(ROOTS_DASHBOARD, `/microsite/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/microsite/reece-chung/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    order: path(ROOTS_DASHBOARD, "/e-commerce/order"),
    brand: path(ROOTS_DASHBOARD, "/e-commerce/brand"),
    category: path(ROOTS_DASHBOARD, "/e-commerce/category"),
    newCat: path(ROOTS_DASHBOARD, "/e-commerce/newCat"),
    newOrder: path(ROOTS_DASHBOARD, "/e-commerce/newOrder"),
    orderDetails: path(ROOTS_DASHBOARD, "/e-commerce/orderDetails"),
    newBrand: path(ROOTS_DASHBOARD, "/e-commerce/newBrand"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
  },
  vault: {
    root: path(ROOTS_DASHBOARD, "/vault"),
    list: path(ROOTS_DASHBOARD, "/vault/list"),
    new: path(ROOTS_DASHBOARD, "/vault/new"),
    edit: (name) => path(ROOTS_DASHBOARD, `vault/vault/${name}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"
    ),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    new: path(ROOTS_DASHBOARD, "/blog/new"),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
